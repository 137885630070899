import * as constant from '../../utils/constant';

const orderInitialState = {
  fetching: false,
  getUserOrders: {},
  error: {},
};

export const GetUserOrders = (state = orderInitialState, action) => {
  switch (action.type) {
    case constant.GET_USER_ORDER_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_USER_ORDER_SUCCESS:
      return {
        ...state,
        getUserOrders: action.payload,
        fetching: false,
      };
    case constant.GET_USER_ORDER_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
