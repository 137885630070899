import { createAction } from "redux-actions";
import * as constant from "../../utils/constant";

export const getUserRequest = createAction(constant.GET_USER_REQUEST);
export const getUserSuccess = createAction(constant.GET_USER_SUCCESS);
export const getUserError = createAction(constant.GET_USER_ERROR);

export const getCartRequest = createAction(constant.GET_CART_REQUEST);
export const getCartSuccess = createAction(constant.GET_CART_SUCCESS);
export const getCartError = createAction(constant.GET_CART_ERROR);

export const addCheckOutRequest = createAction(constant.ADD_CHECKOUT_REQUEST);
export const addCheckOutSuccess = createAction(constant.ADD_CHECKOUT_SUCCESS);
export const addCheckOutError = createAction(constant.ADD_CHECKOUT_ERROR);

export const getProductRequest = createAction(constant.GET_PRODUCT_REQUEST);
export const getProductSuccess = createAction(constant.GET_PRODUCT_SUCCESS);
export const getProductError = createAction(constant.GET_PRODUCT_ERROR);

export const getVendorRequest = createAction(constant.GET_VENDOR_REQUEST);
export const getVendorSuccess = createAction(constant.GET_VENDOR_SUCCESS);
export const getVendorError = createAction(constant.GET_VENDOR_ERROR);

export const getReviewRequest = createAction(constant.GET_REVIEW_REQUEST);
export const getReviewSuccess = createAction(constant.GET_REVIEW_SUCCESS);
export const getReviewError = createAction(constant.GET_REVIEW_ERROR);

export const getReviewDetailRequest = createAction(
  constant.GET_REVIEW_DETAIL_REQUEST
);
export const getReviewDetailSuccess = createAction(
  constant.GET_REVIEW_DETAIL_SUCCESS
);
export const getReviewDetailError = createAction(
  constant.GET_REVIEW_DETAIL_ERROR
);

export const setUserDetailRequest = createAction(
  constant.SET_USER_DETAIL_REQUEST
);
export const setUserDetailSuccess = createAction(
  constant.SET_USER_DETAIL_SUCCESS
);
export const setUserDetailError = createAction(constant.SET_USER_DETAIL_ERROR);

export const getOrderRequest = createAction(constant.GET_ORDER_REQUEST);
export const getOrderSuccess = createAction(constant.GET_ORDER_SUCCESS);
export const getOrderError = createAction(constant.GET_ORDER_ERROR);

export const getOrderDetailRequest = createAction(
  constant.GET_ORDER_DETAIL_REQUEST
);
export const getOrderDetailSuccess = createAction(
  constant.GET_ORDER_DETAIL_SUCCESS
);
export const getOrderDetailError = createAction(
  constant.GET_ORDER_DETAIL_ERROR
);

export const getProductDetailRequest = createAction(
  constant.GET_PRODUCT_DETAIL_REQUEST
);
export const getProductDetailSuccess = createAction(
  constant.GET_PRODUCT_DETAIL_SUCCESS
);
export const getProductDetailError = createAction(
  constant.GET_PRODUCT_DETAIL_ERROR
);

export const getProductImageRequest = createAction(
  constant.GET_PRODUCT_IMAGE_REQUEST
);
export const getProductImageSuccess = createAction(
  constant.GET_PRODUCT_IMAGE_SUCCESS
);
export const getProductImageError = createAction(
  constant.GET_PRODUCT_IMAGE_ERROR
);

export const getUserDetailRequest = createAction(
  constant.GET_USER_DETAIL_REQUEST
);
export const getUserDetailSuccess = createAction(
  constant.GET_USER_DETAIL_SUCCESS
);
export const getUserDetailError = createAction(constant.GET_USER_DETAIL_ERROR);

export const getUserOrderRequest = createAction(
  constant.GET_USER_ORDER_REQUEST
);
export const getUserOrderSuccess = createAction(
  constant.GET_USER_ORDER_SUCCESS
);
export const getUserOrderError = createAction(constant.GET_USER_ORDER_ERROR);

export const getCategoryRequest = createAction(constant.GET_CATEGORY_REQUEST);
export const getCategorySuccess = createAction(constant.GET_CATEGORY_SUCCESS);
export const getCategoryError = createAction(constant.GET_CATEGORY_ERROR);

export const getCategoryDetailRequest = createAction(
  constant.GET_CATEGORY_DETAIL_REQUEST
);
export const getCategoryDetailSuccess = createAction(
  constant.GET_CATEGORY_DETAIL_SUCCESS
);
export const getCategoryDetailError = createAction(
  constant.GET_CATEGORY_DETAIL_ERROR
);

export const RESET_CART = 'RESET_CART';

export const resetCart = () => {
  return { type: RESET_CART };
};
