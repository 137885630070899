import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ResetPasswordAction } from "../../redux/action/ResetPasswordAction";
import { RotatingLines } from "react-loader-spinner";

const NewPassword = () => {
  const [newPasswordError, setNewPasswordError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { key } = useParams();
  const navigate = useNavigate();

  const handleChangePassword = async () => {
    try {
      if (!newPassword || !confirmPassword) {
        setNewPasswordError(newPassword ? "" : "* New Password is required");
        setConfirmPasswordError(
          confirmPassword ? "" : "*Confirm Password is required"
        );
        return;
      }

      const data = {
        userId: key,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };
      const res = await ResetPasswordAction(data, setResetPasswordLoading);
      if (res && res.isSuccess) {
        toast.success(res?.displayMsg);
        navigate("/login");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        toast.error(
          res.data.techMsg || res.data.message || res.data.displayMsg
        );
      }
    } catch (error) {
      // toast.error("An error occurred during login.");
    }
  };

  const PasswordCheck = (e, setPasswordError) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@*()_+\-=[\]{};':"\\|,.<>/?#]).{8,}$/;
    const isPasswordValid = passwordRegex.test(e.target.value);
    const isNotEmpty = e.target.value.length > 0;
    const isLengthValid = e.target.value.length >= 8;
    setPasswordError(
      !isPasswordValid
        ? "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
        : !isLengthValid && isNotEmpty
        ? "Password must be at least 8 characters long."
        : !isNotEmpty
        ? "Password is required."
        : ""
    );
  };

  return (
    <section
      className="position-relative z-2"
      style={{ backgroundColor: "#f4f2f0", padding: "92px 0px" }}
    >
      <div className="card login-container">
        <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
          <div
            className="title-column col-lg-12 col-md-12 col-sm-12"
            style={{ textAlign: "center" }}
          >
            <h2>New Password</h2>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="mb-3 textFiled">
            <div className="input-group">
              <input
                id="newPassword"
                name="newPassword"
                type={!showNewPassword ? "password" : "text"}
                className="form-control m-0"
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  PasswordCheck(e, setNewPasswordError);
                }}
              />
              <span
                className="input-group-text py-0"
                onClick={() => {
                  setShowNewPassword(!showNewPassword);
                }}
              >
                {!showNewPassword ? (
                  <i className="bi bi-eye-slash"></i>
                ) : (
                  <i className="bi bi-eye"></i>
                )}
              </span>
            </div>
            <span className="text-danger">{newPasswordError}</span>
          </div>
          <div className="mb-3 textFiled">
            <div className="input-group">
              <input
                id="confirmPassword"
                name="confirmPassword"
                type={!showConfirmPassword ? "password" : "text"}
                className="form-control m-0"
                placeholder="Enter Confirm Password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  PasswordCheck(e, setConfirmPasswordError);
                }}
              />
              <span
                className="input-group-text py-0"
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              >
                {!showConfirmPassword ? (
                  <i className="bi bi-eye-slash"></i>
                ) : (
                  <i className="bi bi-eye"></i>
                )}
              </span>
            </div>
            <span className="text-danger error-message">
              {confirmPasswordError}
            </span>
          </div>

          <div className="d-grid mt-2 mb-5 loginBtn">
            <Link
              className="btn"
              style={{ backgroundColor: "#332a16", width: "160px" }}
              onClick={handleChangePassword}
            >
              <text type="button" className="text-light">
                {resetPasswordLoading ? (
                  <RotatingLines
                    strokeColor="#fff"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible
                  />
                ) : (
                  "Change Password"
                )}
              </text>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewPassword;
