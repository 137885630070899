import React from "react";
import "./about.css";

export const About = () => {
  return (
    <section
      className="testimonials-section p-3"
      style={{ backgroundColor: "#f4f2f0" }}
    >
      <div className="container position-relative z-1">
        <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
          <div className="row clearfix">
            <div
              className="title-column col-lg-12 col-md-12 col-sm-12"
              style={{ textAlign: "center" }}
            >
              <h2>Our Story</h2>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 wow fadeIn animated">
            <p className="outStoryContent">
              <span className="outStortContentParagraph"> In 2013 </span> ,
              business students from Hickingbotham Hall at Ouachita Baptist University celebrated a winning business competition entry: Dr. Jack’s Coffeehouse. Named after the university’s first president, John “Jack” Conger, Dr. Jack’s initially aimed to support missions in Honduras through the sale of its own coffee beans. As the original founders graduated, responsibility for the business was passed down to new students each year, fostering a decade-long tradition of entrepreneurship and philanthropy. At its core, Dr. Jack’s is coffee with a purpose.
            </p>
          </div>
          <p className="outStoryContent">
          Today, Dr. Jack’s has evolved into a coffee hub, connecting customers with mission-focused coffee products. The coffee vendors featured on our website align with our primary goal: to give back. While we sell these vendors’ coffee online, our original coffee beans remain a staple at the Ouachita Baptist University coffee shop in the student center.
          </p>
          <p className="outStoryContent">
          Dr. Jack's is currently led by a dedicated team of students committed to upholding the mission of “Coffee with a Purpose.” Our vendors support various causes, including veterans, economic development, evangelism, combating human trafficking, and more. Proceeds from our sales are invested in the needs of women and children in Honduras, where students annually go on mission trips to build homes each year. During these trips, we address the physical needs of the Honduran people while also tending to their spiritual well-being by teaching them and demonstrating God’s love. Dr. Jack's stands as proof that student-led businesses can make a difference while also sharing Jesus with the nations. Your purchase of coffee not only supports our students but also contributes to global humanitarian efforts. Join us in our mission and enjoy exceptional coffee while making a meaningful difference!
          </p>
        </div>
      </div>
    </section>
  );
};
