export const Plus = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <rect
        x="22"
        y="10"
        width="2"
        height="22"
        rx="1"
        transform="rotate(90 22 10)"
        fill="black"
      />
      <rect x="10" width="2" height="22" rx="1" fill="black" />
    </svg>
  );
};
export const Minus = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <rect
        x="22"
        y="10"
        width="2"
        height="22"
        rx="1"
        transform="rotate(90 22 10)"
        fill="black"
      />
    </svg>
  );
};

export const LoaderSvg = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: "auto",
        background: "transparent",
        display: "block",
        shapeRendering: "auto",
      }}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#322a13"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};
