import { toast } from "react-toastify";
import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";
import * as actions from "./index";

export const AddCheckOutAction = (data) => {
  return async (dispatch) => {
    const token = getToken();
    try {
      dispatch(actions.addCheckOutRequest());

      const res = await apiInstance.post("cart/checkout", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200 || res.status === 201) {
        dispatch(actions.addCheckOutSuccess(res.data.data));
        return res.data.data;
      } else {
        console.error("Unexpected response status:", res.status);
        toast.error("Unexpected response status");
        dispatch(actions.addCheckOutError());
        throw new Error("Unexpected response status");
      }
    } catch (err) {
      console.error(
        "Error in AddCheckOutAction:",
        err.response ? err.response.data : err.message
      );
      toast.error(
        err?.response?.data?.message || "An error occurred during checkout"
      );
      dispatch(actions.addCheckOutError());
      throw err; // Ensure the error is re-thrown
    }
  };
};
