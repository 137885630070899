import { combineReducers } from "redux";
import { GetAllUser } from "./GetUser";
import { getAllProduct } from "./GetProduct";
import { getCartList } from "./GetCartList";
import { getProductDetail } from "./GetProductDetail";
import { setUserDetail } from "./SetUserDetail";
import { getCheckOutData } from "./GetCheckOutData";
import { getOrders } from "./GetOrders";
import { getReviewDetail } from "./GetReviewDetail";
import { getProductImage } from "./GetProductImage";
import { GetUserDetail } from "./GetUserDetail";
import { GetUserOrders } from "./GetUserOrders";
import { getAllCategory } from "./GetCategory";
import { getCategoryDetails } from "./GetCategoryDetails";
import { getAllVendor } from "./GetVendor";

export const rootReducer = combineReducers({
  GetAllUser,
  getAllProduct,
  getCartList,
  getProductDetail,
  setUserDetail,
  getCheckOutData,
  getOrders,
  getReviewDetail,
  getProductImage,
  GetUserDetail,
  GetUserOrders,
  getAllCategory,
  getCategoryDetails,
  getAllVendor,
});
