import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoginAction } from "../../redux/action/Login";
import { EMAIL_REGEX } from "../../utils/constant";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import "../../assets/css/style.css";
import "./login.css";
import { UserDetailsAction } from "../../redux/action/GetUserDetail";
import { useDispatch } from "react-redux";
import { GetCartListAction } from "../../redux/action/GetCartListAction";
import { ForgotPassword } from "../../redux/action/ForgotPassword";

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [changeEmail, setChangeEmail] = useState("");
  const [changeEmailError, setChangeEmailError] = useState("");
  const [showChangeEmail, setShowChangeEmail] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.state?.url || "/";

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleEmailEvent = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handlePasswordEvent = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleClick = async () => {
    try {
      if (!email || !password) {
        setEmailError(email ? "" : "* Email is required");
        setPasswordError(password ? "" : "* Password is required");
        return;
      }

      if (!EMAIL_REGEX.test(email)) {
        setEmailError("* Invalid Email");
        return;
      }

      const data = {
        email: email,
        password,
      };

      const res = await LoginAction(data, setLoading);
      if (res && res?.isSuccess) {
        toast.success("Login successful!");
        setLoading(false);
        setEmail("");
        setPassword("");
        dispatch(UserDetailsAction(res?.data?.user?._id, navigate));
        dispatch(GetCartListAction());
        navigate(url);
      } else {
        toast.error(res?.data?.techMsg || res?.data?.message);
      }
    } catch (error) {
      toast.error("An error occurred during login.");
    }
  };

  const handleChangeEmailClick = async () => {
    try {
      if (!changeEmail) {
        setChangeEmailError(changeEmail ? "" : "* Email is required");
        return;
      }

      if (!EMAIL_REGEX.test(changeEmail)) {
        setChangeEmailError("* Invalid Email");
        return;
      }

      const data = {
        email: changeEmail,
      };
      const res = await ForgotPassword(data, setForgotPasswordLoading);
      if (res && res.isSuccess) {
        toast.success("Email Sent! Please Check Your Email!");
        setChangeEmail("");
      } else {
        toast.error(res.data.techMsg || res.data.message);
        toast.error(res.data.displayMsg);
      }
    } catch (error) {
      // toast.error("An error occurred during login.");
    }
  };

  return (
    <>
      <section style={{ backgroundColor: "#f4f2f0", padding: "92px 0px" }}>
        <div className="card login-container position-relative z-3">
          <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
            <div
              className="title-column col-lg-12 col-md-12 col-sm-12"
              style={{ textAlign: "center" }}
            >
              <h2>{showChangeEmail ? "Forgot Password" : "Login"}</h2>
            </div>
          </div>

          {showChangeEmail ? (
            <>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="mb-3 textFiled">
                  <div className="input-group">
                    <input
                      type={"email"}
                      className="form-control m-0"
                      placeholder="Enter Email"
                      value={changeEmail}
                      onChange={(e) => {
                        setChangeEmail(e.target.value);
                        setChangeEmailError("");
                      }}
                    />
                  </div>
                  <span className="text-danger">{changeEmailError}</span>
                </div>
                <div className="d-grid mt-2 mb-3 loginBtn">
                  <Link
                    className="btn"
                    style={{ backgroundColor: "#332a16" }}
                    onClick={handleChangeEmailClick}
                  >
                    <text type="button" className="text-light">
                      {forgotPasswordLoading ? (
                        <RotatingLines
                          strokeColor="#fff"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible
                        />
                      ) : (
                        "Send Mail"
                      )}
                    </text>
                  </Link>
                </div>
                <p className="signup-option mb-1">
                  <Link
                    onClick={() => {
                      setShowChangeEmail(false);
                    }}
                  >
                    Back to Login
                  </Link>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="mb-3 textFiled">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailEvent}
                  />
                  <span className="text-danger">{emailError}</span>
                </div>
                <div className="mb-3 textFiled">
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control m-0"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordEvent}
                    />
                    <span
                      className="input-group-text py-0"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </span>
                  </div>
                  <span className="text-danger">{passwordError}</span>
                </div>
                <p className="signup-option">
                  <Link
                    onClick={() => {
                      setShowChangeEmail(true);
                    }}
                  >
                    Forgot your password?
                  </Link>
                </p>
                <div className="d-grid mt-2 loginBtn mb-3">
                  <Link
                    className="btn"
                    style={{ backgroundColor: "#332a16" }}
                    onClick={handleClick}
                  >
                    <text type="button" className="text-light ">
                      {loading ? (
                        <RotatingLines
                          strokeColor="#fff"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible
                        />
                      ) : (
                        "Login"
                      )}
                    </text>
                  </Link>
                </div>
              </div>
            </>
          )}
          <div className="mb-3 d-flex flex-column justify-content-center align-items-center">
            <h6> or </h6>
            <p className="signup-option">
              Don't have an account? <Link to="/signUp"> Sign Up</Link>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
