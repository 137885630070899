import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";

export const DeleteCartDataAction = async (productId) => {
  const token = getToken()
  try {
    const res = await apiInstance.post("cart/clear",{
      product: productId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.error('Error deleting cart:', err);
    return null;
  }
};
