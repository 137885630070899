import * as constant from '../../utils/constant';

const initialState = {
  fetching: false,
  userGetAllData: [],
  error: {},
};

export const GetAllUser = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_USER_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_USER_SUCCESS:
      return {
        ...state,
        userGetAllData: action.payload,
        fetching: false,
      };
    case constant.GET_USER_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
