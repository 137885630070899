import React, { useEffect, useState } from "react";
import "./nav.css";
import { useSelector } from "react-redux";
import { getToken } from "../../utils/Storage";

export const CartBadge = () => {
  const isLoggedIn = !getToken();
  const [productCount, setProductCount] = useState(0);
  const getCartListState = useSelector(
    (state) => state?.getCartList?.cartGetAllData
  );
  const cartItemsCount = getCartListState?.cartItems?.length || 0;

  useEffect(() => {
    const count = getCartListState?.cartItems?.map((e) => {
      return e.quantity;
    });
    let sum = 0;
    for (let i = 0; i < count?.length; i++) {
      sum += count[i];
    }
    setProductCount(sum);
  }, [getCartListState]);

  return (
    <div className="cart-badge">
      <span>
        {isLoggedIn ? 0 : productCount === NaN ? cartItemsCount : productCount}
      </span>
    </div>
  );
};
