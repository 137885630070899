import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import { GetCategoryDetailAction } from "../../redux/action/GetCategoryDetailAction";
import { useEffect, useState } from "react";
// import { imageURL } from "../../utils/constant";
import "./category.css";
import SearchFilter from "../../components/SearchFilter";
import ProductCard from "../../components/ProductCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderSvg } from "../../assets/AllSvgs";
import OriginsCategoryTab from "./OriginsCategoryTab";
import MissionsCategoryTab from "./MissionsCategoryTab";
import RoastCategoryTab from "./RoastCategoryTab";

export const CategoryDetails = () => {
  const { category } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  // const category = location?.state?.category;
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState("atoz");
  const [missions, setMissions] = useState("");
  const [origins, setOrigins] = useState("");
  const [roast, setRoast] = useState("");
  const [page, setPage] = useState(1);
  const [loaderPage, setLoaderPage] = useState(false);
  const imageURL = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    switch (category) {
      case "roast":
        setRoast("Medium");
        setPage(1);
        break;
      case "missions":
        setMissions("End Human Trafficking");
        setPage(1);
        break;
      case "origins":
        setOrigins("Blend");
        setPage(1);
        break;
      default:
        break;
    }
  }, [category]);

  const getCategoryDetailState = useSelector(
    (state) => state?.getCategoryDetails?.categoryDetails
  );

  const fetching = useSelector((state) => state.getCategoryDetails.fetching);

  const CircularProgress = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
          color: "black",
        }}
      >
        <Loader />
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(
          GetCategoryDetailAction(
            page,
            search,
            filters,
            missions,
            origins,
            roast,
            setLoaderPage
          )
        );
      } catch (error) {
        console.error("Error fetching product details", error);
      }
    };
    if (category && (missions || origins || roast)) {
      fetchData();
    }
  }, [search, category, filters, missions, origins, roast]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <section
        className="services-section category-tab position-relative z-1"
        style={{ paddingTop: "50px" }}
      >
        <div className="container">
          <div className="sec-title style-two">
            <div>
              <div className="d-flex align-items-center my-3">
                <div
                  className="back-arrow d-flex align-items-center me-2"
                  onClick={handleGoBack}
                >
                  <i
                    className="bi bi-arrow-left"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
                <div className="title-column m-0">
                  <h2 className="m-0">{category}</h2>
                </div>
              </div>
              <SearchFilter
                setFilters={setFilters}
                setSearch={setSearch}
                setPage={setPage}
              />
            </div>
          </div>
          {category === "roast" && (
            <RoastCategoryTab setRoast={setRoast} setPage={setPage} />
          )}
          {category === "missions" && (
            <MissionsCategoryTab setMissions={setMissions} setPage={setPage} />
          )}
          {category === "origins" && (
            <OriginsCategoryTab setOrigins={setOrigins} setPage={setPage} />
          )}
          {fetching && !loaderPage ? (
            <CircularProgress />
          ) : (
            <InfiniteScroll
              className="hide-scroll"
              dataLength={getCategoryDetailState?.result?.length ?? 10}
              next={() => {
                if (getCategoryDetailState?.meta?.nextPage) {
                  setLoaderPage(true);
                  setPage(page + 1);
                  dispatch(
                    GetCategoryDetailAction(
                      page + 1,
                      search,
                      filters,
                      missions,
                      origins,
                      roast,
                      setLoaderPage
                    )
                  );
                }
              }}
              loader={loaderPage && <LoaderSvg width={50} height={50} />}
              hasMore={
                getCategoryDetailState?.meta?.totalPages ===
                  getCategoryDetailState?.meta?.pageNumber + 1 ||
                getCategoryDetailState?.meta?.totalPages === 1
              }
            >
              <div className="row clearfix">
                {getCategoryDetailState?.result?.length > 0 ? (
                  getCategoryDetailState?.result?.map((product, index) => {
                    const image_Id = product?.image?.[0];
                    const imageUrl = image_Id
                      ? `${imageURL + image_Id}`
                      : "../../assets/images/banner1-1.jpg";
                    return (
                      <ProductCard
                        cardClassName={"col-xl-3 col-lg-4 col-md-6 col-12"}
                        imageUrl={imageUrl}
                        key={index?._id}
                        productDescription={product?.description}
                        productId={product?._id}
                        productPrice={product?.finalPrice}
                        productTitle={product?.title}
                        productBean={product?.bean}
                        isAddedByAdmin={product?.isAddedByAdmin}
                        vendorName={
                          (product?.vendorDetails?.first_name ?? "vendor") +
                          " " +
                          (product?.vendorDetails?.last_name ?? "")
                        }
                      />
                    );
                  })
                ) : (
                  <div className="no-products-found">
                    <p>No products found</p>
                  </div>
                )}
              </div>
            </InfiniteScroll>
          )}
          {getCategoryDetailState?.meta?.totalCount ===
            getCategoryDetailState?.result?.length &&
          getCategoryDetailState?.result?.length ? (
            <p className="m-0" style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          ) : null}
        </div>
      </section>
    </>
  );
};
