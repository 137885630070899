import React from "react";
import "./gallery.css";
import { Product } from "../product/product";

export const OurProducts = () => {
  // const data = [{
  //   id: 1,
  //   name: "Coffee Making",
  //   image: "https://images.unsplash.com/photo-1523063308874-cecc260a3171?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=980&h=980&fit=crop&s=9631adb2d2f752e3a0734f393fef634b"
  // }, {
  //   id: 2,
  //   name: "Coffee Bean",
  //   image: "https://images.unsplash.com/photo-1625021659243-3f9d4ae62ed1?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=900&h=900&fit=crop&s=b1222b6a1d3694cac76d2a23c3a02254"
  // }, {
  //   id: 3,
  //   name: "Whale",
  //   image: "https://images.unsplash.com/photo-1648377015755-9914af49f7fe?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=900&h=900&fit=crop&s=3c55430f01fe9ac9a9ccb3383d1416ff"
  // }, {
  //   id: 4,
  //   name: "Cafe",
  //   image: "https://plus.unsplash.com/premium_photo-1663932464937-e677ddfc1d55?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=900&h=900&fit=crop&s=e81cb6a60c53788559edb9bec21b80fc"
  // }, {
  //   id: 5,
  //   name: "Boat",
  //   image: "https://images.unsplash.com/photo-1542372147193-a7aca54189cd?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=900&h=900&fit=crop&s=e81cb6a60c53788559edb9bec21b80fc"
  // }, {
  //   id: 6,
  //   name: "Flowers",
  //   image: "https://images.unsplash.com/photo-1445116572660-236099ec97a0?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=900&h=900&fit=crop&s=e81cb6a60c53788559edb9bec21b80fc"
  // }, {
  //   id: 7,
  //   name: "Fire",
  //   image: "https://images.unsplash.com/photo-1531752074002-abf991376d04?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=900&h=900&fit=crop&s=e81cb6a60c53788559edb9bec21b80fc"
  // }, {
  //   id: 8,
  //   name: "Coffee",
  //   image: "https://images.unsplash.com/photo-1507133750040-4a8f57021571?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=900&h=900&fit=crop&s=e81cb6a60c53788559edb9bec21b80fc  "
  // },];

  // const Tile = ({ data }) => {
  //   const [state, setState] = useState({
  //     open: false,
  //     mouseOver: false
  //   });

  //   const _mouseLeave = (e) => {
  //     e.preventDefault();
  //     if (state.mouseOver) {
  //       setState({
  //         ...state,
  //         mouseOver: false
  //       });
  //     }
  //   }

  //   const _clickHandler = (e) => {
  //     e.preventDefault();
  //     setState(prevState => ({
  //       ...prevState,
  //       open: !prevState.open
  //     }));
  //   }

  //   return (
  //     <div className="tile">
  //       <img
  //         onMouseLeave={_mouseLeave}
  //         onClick={_clickHandler}
  //         src={data.image}
  //         alt={data.name}
  //       />
  //     </div>
  //   );
  // };

  return (
    <section
      className="testimonials-section"
      style={{ backgroundColor: "#f4f2f0" }}
    >
      {/* <div className="order-list"> */}
      {/* <div className="sec-title style-two" style={{ marginBottom: "0px", }}>
          <div className="row clearfix">
            <div
              className="title-column col-lg-12 col-md-12 col-sm-12"
              style={{ textAlign: "center" }}
            >
              <h2>Gallery</h2>
            </div>
          </div>
        </div>
        <div className="tiles">
          {data.map((item) => (
            <Tile key={item.id} data={item} />
          ))}
        </div> */}
      <Product />
      {/* </div> */}
    </section>
  );
};
