import * as constant from "../../utils/constant";
import { RESET_CART } from "../action";

const initialState = {
  fetching: false,
  cartGetAllData: {},
  error: {},
};

export const getCartList = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_CART_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_CART_SUCCESS:
      return {
        ...state,
        cartGetAllData: action.payload,
        fetching: false,
      };
    case constant.GET_CART_ERROR:
      return {
        ...state,
        fetching: false,
      };
      case RESET_CART:
        return initialState;
    default:
      return state;
  }
};
