import { apiInstance } from "../../httpclient";

export const AddUser = async (data, setLoading) => {
  setLoading(true);
  try {
    const res = await apiInstance.post("user/add", data);
    setLoading(false);
    if (res.status === 200) {
      return res.data;
    }
    return res;
  } catch (err) {
    setLoading(false);
    return err.response;
  }
};
