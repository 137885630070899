import * as constant from "../../utils/constant";

const initialState = {
  fetching: false,
  productGetAllData: {},
  error: {},
};

export const getAllProduct = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_PRODUCT_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        productGetAllData:
          action.payload.meta.pageNumber > 1
            ? {
                ...action.payload,
                result: [
                  ...state.productGetAllData.result,
                  ...action.payload.result,
                ],
              }
            : action.payload,
        fetching: false,
      };
    case constant.GET_PRODUCT_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
