import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({
  cardClassName,
  productId,
  key,
  imageUrl,
  productPrice,
  productTitle,
  productDescription,
  productBean,
  vendorName,
  isAddedByAdmin,
}) => {
  return (
    <>
      <div className={`shop-item ${cardClassName}`} key={key}>
        <div className="inner-box h-100">
          <div className="image-box">
            <Link to={`/productDetail/${productId}`}>
              <div className="image">
                <img src={imageUrl} crossorigin="anonymous" />
                <div className="p-3 border-top">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <p className="text-dark fw-bold mb-0">{productTitle}</p>
                      <p className="text-dark text-sm mb-0">
                        By{" "}
                        {isAddedByAdmin === 1 || isAddedByAdmin === true
                          ? "Dr Jack's"
                          : vendorName}
                      </p>
                    </div>
                    <p className="text-dark fw-bold fs-4 mb-0">
                      ${Number(productPrice)?.toFixed(2)}
                    </p>
                  </div>
                  <p className="font-weight-bold text-dark text-ellipsis-two-line mb-1">
                    {productDescription}
                  </p>
                </div>
                <div className="overlay-box">
                  <div className="cart-option">
                    <div>
                      <span className="flaticon-eye"></span>
                    </div>
                    {productTitle}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
