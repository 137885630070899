import React from "react";
import { Form } from "react-bootstrap";

const SearchFilter = ({ setFilters, setSearch, setPage }) => {
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilters(selectedFilter); 
    setPage(1);
  };
  
  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <Form.Control
          type="search"
          id="search"
          className="mx-240"
          placeholder="Search..."
          onChange={(e) => {
            setPage(1);
            setSearch(e.target.value);
          }}
        />
        <Form.Select
          aria-label="Default select example"
          className="mx-240"
          placeholder="filters"
          onChange={handleFilterChange}
        >
          <option value="atoz">Sort by A-Z</option>
          <option value="priceLow">Price - low to high</option>
          <option value="priceHigh">Price - high to low</option>
        </Form.Select>
      </div>
    </>
  );
};

export default SearchFilter;
