import React, { useState, useCallback, useEffect } from "react";
import "./profile.css";
import "../../assets/css/style.css";
import { UpdateUserAction } from "../../redux/action/UpdateUserAction";
import { useDispatch, useSelector } from "react-redux";
import { UserDetailsAction } from "../../redux/action/GetUserDetail";
import { EMAIL_REGEX } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import { getUserId } from "../../utils/Storage";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { CircularProgress } from "../../components/CommonLoader";

export const UserProfile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState();
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const userId = getUserId();

  const userDetails = useSelector((state) => state.GetUserDetail.userGetData);

  const fetching = useSelector((state) => state.GetUserDetail.fetching);

  const handleUserDetail = (id, navigate) =>
    dispatch(UserDetailsAction(id, navigate));

  const [user, setUser] = useState({
    id: userDetails?._id || "",
    first_name: userDetails?.first_name || "",
    last_name: userDetails?.last_name || "",
    email: userDetails?.email || "",
    status: userDetails?.status || "",
    password: userDetails?.password || "",
    address: userDetails?.address || "",
    address2: userDetails.address2 || "",
    city: userDetails?.city || "",
    pincode: userDetails?.pincode || "",
    phone_number: userDetails?.phone_number || "",
    state: userDetails?.state || "",
    country: userDetails?.country || "USA",
  });

  useEffect(() => {
    if (userDetails) {
      setUser({
        id: userDetails?._id || "",
        first_name: userDetails?.first_name || "",
        last_name: userDetails?.last_name || "",
        email: userDetails?.email || "",
        status: userDetails?.status || "",
        password: userDetails?.password || "",
        address: userDetails?.address || "",
        address2: userDetails.address2 || "",
        city: userDetails?.city || "",
        pincode: userDetails?.pincode || "",
        phone_number: userDetails?.phone_number || "",
        state: userDetails?.state || "",
        country: userDetails?.country || "USA",
      });
    } else {
      setUser({
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        status: "",
        password: "",
        address: "",
        address2: "",
        city: "",
        pincode: "",
        phone_number: "",
        state: "",
        country: "USA",
      });
    }
  }, [userDetails]);

  useEffect(() => {
    handleUserDetail(userId, navigate);
  }, []);

  const handelSubmit = async () => {
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setAddressError("");
    setCityError("");
    setPincodeError("");
    setPhoneNumberError("");
    setStateError("");
    setCountryError("");

    let hasErrors = false;

    if (user.first_name.length < 2) {
        setFirstNameError("* First Name must be at least 2 characters");
        hasErrors = true;
    }

    if (user.last_name.length < 2) {
        setLastNameError("* Last Name must be at least 2 characters");
        hasErrors = true;
    }

    if (user.email.length === 0) {
        setEmailError("* Email is required");
        hasErrors = true;
    } else if (!EMAIL_REGEX.test(user.email)) {
        setEmailError("* Invalid Email");
        hasErrors = true;
    }

    if (user.address.length === 0) {
        setAddressError("* Address is required");
        hasErrors = true;
    }

    if (user.city.length === 0) {
        setCityError("* City Name is required");
        hasErrors = true;
    }

    if (user.state.length === 0) {
        setStateError("* State Name is required");
        hasErrors = true;
    }

    if (user.country.length === 0) {
        setCountryError("* Country Name is required");
        hasErrors = true;
    }

    if (user.phone_number.length === 0) {
        setPhoneNumberError("* Phone Number is required");
        hasErrors = true;
    } else {
        const digitCount = user.phone_number.replace(/\D/g, "").length;
        if (digitCount !== 10) {
            setPhoneNumberError("* Phone Number must contain exactly 10 digits");
            hasErrors = true;
        }
    }

    if (user.pincode.length !== 5 || !/^\d+$/.test(user.pincode)) {
        setPincodeError("* Zip must contain exactly 5 digits");
        hasErrors = true;
    }

    if (hasErrors) {
      return;
  }

    if (userDetails) {
      try {
        const handleBody = {
          id: user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email,
          address: user?.address,
          address2: user?.address2,
          city: user?.city,
          pincode: user?.pincode,
          phone_number: user?.phone_number,
          status: user?.status,
          state: user?.state,
          country: user?.country,
        };
        setLoading(true);
        const response = await UpdateUserAction(handleBody);
        setLoading(false);
        if (response.isSuccess) {
          toast.success("User update successfully.");
        } else {
          setErrorOpen(true);
          setErrorMsg(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        setErrorOpen(true);
        setErrorMsg("An error occurred while processing your request.");
      }
    }
  };

  const handleFormData = useCallback(
    (e) => {
      const { name, value } = e.target;
      setUser({ ...user, [name]: value });
      switch (name) {
        case "first_name":
          setFirstNameError(
            value.length >= 2 ? "" : "* First Name is required"
          );
          break;
        case "last_name":
          setLastNameError(value.length >= 2 ? "" : "* Last Name is required");
          break;
        case "email":
          setEmailError(value.length > 0 ? "" : "* Email is required");
          break;
        case "address":
          setAddressError(value.length > 0 ? "" : "* Address is required");
          break;
        case "city":
          setCityError(value.length > 0 ? "" : "* City Name is required");
          break;
        case "state":
          setStateError(value.length > 0 ? "" : "* State Name is required");
          break;
        case "country":
          setCountryError(value.length > 0 ? "" : "* Country Name is required");
          break;
        case "pincode":
          setPincodeError(
            value.length === 5 && /^\d+$/.test(value)
              ? ""
              : "* Zip must contain exactly 5 digits"
          );
          break;
        case "phone_number":
          setPhoneNumberError(
            value.length > 0 ? "" : "* Phone Number is required"
          );
          break;

        default:
          break;
      }
    },
    [user]
  );

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleCancel = () => {
    navigate("/");
  };

  const stateOptions = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const handleStateChange = (e) => {
    setUser({ ...user, state: e.target.value });
  };

  return (
    <>
      <div className="profileContainer" style={{ backgroundColor: "#f4f2f0" }}>
        <div className="d-flex justify-content-between align-items-center position-relative z-1">
          <div className="userProftitle mt-5">
            <h2>
              User <span>Profile</span>
            </h2>
          </div>
          <div className="d-flex">
            <button className="save-btn me-4" onClick={handelSubmit}>
              Save
            </button>
            <button className="cancel-btn me-2" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>

        <div className="mb-5 card p-2 justify-content-center border bborder-light-subtle shadow-lg p-3 bg-body-tertiary rounded position-relative z-1">
          {fetching || loading ? (
            <CircularProgress />
          ) : (
            <>
              <div className="mb-2 row d-flex  ">
                <h4 className="mb-4">
                  <span className="mb-4 border-bottom">
                    <b>Basic Info</b>
                  </span>
                </h4>
                <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    First Name <span className="red-asterisk">*</span>
                  </label>
                  <input
                    value={user.first_name}
                    onChange={handleFormData}
                    name="first_name"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="First Name"
                  />
                  <span className="text-danger">{firstNameError}</span>
                </div>
                <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                  <label for="exampleFormControlInput1" className="form-label">
                    Last Name <span className="red-asterisk">*</span>
                  </label>
                  <input
                    value={user.last_name}
                    onChange={handleFormData}
                    name="last_name"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Last Name"
                  />
                  <span className="text-danger">{lastNameError}</span>
                </div>
                <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                  <label for="exampleFormControlInput1" className="form-label">
                    Email <span className="red-asterisk">*</span>
                  </label>
                  <input
                    value={user.email}
                    disabled
                    onChange={handleFormData}
                    name="email"
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter your Email"
                  />
                  <span className="text-danger">{emailError}</span>
                </div>
                <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                  <label for="exampleFormControlInput1" className="form-label">
                    Phone Number <span className="red-asterisk">*</span>
                  </label>
                  <InputMask
                    mask="(999) 999-9999"
                    value={user.phone_number}
                    onChange={handleFormData}
                    name="phone_number"
                    className="form-control"
                    placeholder="Phone Number"
                  />
                  {phoneNumberError && (
                    <span className="text-danger">{phoneNumberError}</span>
                  )}
                </div>
              </div>
              <div className="border mb-4" />
              <div className=" mb-4  row d-flex justify-content-center">
                <h4 className="mb-4">
                  <span className="border-bottom">
                    <b>Address</b>
                  </span>
                </h4>
                <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                  <label for="exampleFormControlInput1" className="form-label">
                    Address Line 1 <span className="red-asterisk">*</span>
                  </label>
                  <input
                    value={user.address}
                    className="form-control"
                    onChange={handleFormData}
                    name="address"
                    id="exampleFormControlInput1"
                    placeholder="Address 1"
                  />
                  <span className="text-danger">{addressError}</span>
                </div>
                <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                  <label for="exampleFormControlInput1" className="form-label">
                    Address Line 2
                  </label>
                  <input
                    value={user.address2}
                    className="form-control"
                    onChange={handleFormData}
                    name="address2"
                    id="exampleFormControlInput1"
                    placeholder="Address 2"
                  />
                  {/* <span className="text-danger">{addressError}</span> */}
                </div>
                <div className="mb-3 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    City <span className="red-asterisk">*</span>
                  </label>
                  <input
                    value={user.city}
                    className="form-control"
                    onChange={handleFormData}
                    name="city"
                    id="exampleFormControlInput1"
                    placeholder="City"
                  />
                  <span className="text-danger">{cityError}</span>
                </div>
                <div className="mb-3 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <label htmlFor="stateSelect" className="form-label">
                    State <span className="red-asterisk">*</span>
                  </label>
                  <select
                    className="form-select"
                    onChange={handleStateChange}
                    name="state"
                    id="stateSelect"
                    value={user.state}
                  >
                    <option value="">{user.state}</option>
                    {stateOptions.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <span className="text-danger">{stateError}</span>
                </div>
                <div className="mb-3 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
                  <label for="exampleFormControlInput1" className="form-label">
                    Country <span className="red-asterisk">*</span>
                  </label>
                  <input
                    value={user.country}
                    className="form-control"
                    onChange={handleFormData}
                    name="country"
                    id="exampleFormControlInput1"
                    placeholder="Country"
                  />
                  <span className="text-danger">{countryError}</span>
                </div>
                <div className="mb-3 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    ZIP <span className="red-asterisk">*</span>
                  </label>
                  <input
                    value={user.pincode}
                    className="form-control"
                    onChange={handleFormData}
                    name="pincode"
                    // type="number"
                    id="exampleFormControlInput1"
                    placeholder="Zip Code"
                    inputMode="numeric"
                    maxLength={5}
                  />
                  <span className="text-danger">{pincodeError}</span>
                </div>
              </div>
            </>
          )}
        </div>

        {isErrorOpen && (
          <div
            className="alert alert-danger d-flex justify-content-between"
            role="alert"
          >
            {errorMsg}
            <button
              type="button"
              className="btn-close"
              onClick={handleErrorClose}
            ></button>
          </div>
        )}
      </div>
    </>
  );
};
