import * as constant from "../../utils/constant";

const initialState = {
  fetching: false,
  categoryDetails: {},
  error: {},
};

export const getCategoryDetails = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_CATEGORY_DETAIL_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        categoryDetails:
          action.payload.meta.pageNumber > 1
            ? {
                ...action.payload,
                result: [
                  ...state?.categoryDetails?.result,
                  ...action.payload.result,
                ],
              }
            : action.payload,
        fetching: false,
      };
    case constant.GET_CATEGORY_DETAIL_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
