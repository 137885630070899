import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className="mainView">
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </div>
    </React.Fragment>
  );
}

export default App;
