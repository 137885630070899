import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";

export const UpdateQuantity = async (cardData) => {
  const token = getToken();
  try {
    const res = await apiInstance.post("cart/updateQuantity", cardData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
  } catch (err) {
    return null;
  }
};
