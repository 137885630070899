import React from "react";
import "./../about/about.css";

export const Policy = () => {
  return (
    <section
      className="testimonials-section p-3"
      style={{ backgroundColor: "#f4f2f0" }}
    >
      <div className="auto-container">
        <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
          <div className="row clearfix">
            <div
              className="title-column col-lg-12 col-md-12 col-sm-12"
              style={{ textAlign: "center" }}
            >
              <h2>Privacy Policy</h2>
            </div>
          </div>
        </div>
        <div className="clearfix">
          <div className="wow fadeIn animated">
            <div className="outStoryContent">
              <p className="outStortContentParagraph">
                Privacy Policy - Dr. Jack's
              </p>
              <h6 className="outStoryContent mb-3">
                Privacy Policy - Dr. Jack's Coffee
              </h6>
              <h6 className="outStoryContent">
                Effective Date: February 23, 2024
              </h6>
            </div>
          </div>
          <p className="outStoryContentText mb-2">
            While you can browse sections of the Platform without the need of
            sharing any information with us, however, please note we do not
            offer any product or service under this Platform outside India.. By
            visiting this Platform, providing your information or availing out
            product/service, you expressly agree to be bound by the terms and
            conditions of this Privacy Policy, the Terms of Use and the
            applicable service/product terms and conditions, and agree to be
            governed by the laws of India including but not limited to the laws
            applicable to data protection and privacy. If you do not agree
            please do not use or access our Platform.
          </p>
          <div>
            <p className="outStoryContent fw-bold mb-2">
              1. Information We Collect
            </p>
            <div className="mb-2">
              <p className="outStoryContent header-name mb-1">
                1.1 Personal Information:
              </p>
              <p className="outStoryContentText">
                - Name, email address, phone number, and postal address.
              </p>
              <p className="outStoryContentText">
                - Payment information, such as credit card details, necessary
                for completing transactions.
              </p>
            </div>
            <div className="mb-2">
              <p className="outStoryContent header-name mb-1">
                1.2 Non-Personal Information:
              </p>
              <p className="outStoryContentText">
                - Website usage data, including IP addresses, browser
                information, and pages visited.
              </p>
              <p className="outStoryContentText">
                - We want to reassure our customers that we only collect
                personal data essential for order processing and do not gather
                any additional personal information beyond what is necessary.
              </p>
            </div>
          </div>
          <div>
            <p className="outStoryContent fw-bold mb-2">
              2. Use of Information:
            </p>
            <div className="mb-2">
              <p className="outStoryContent header-name mb-1">
                2.1 Providing Services:
              </p>
              <p className="outStoryContentText">
                - Processing orders, payments, and delivering products or
                services.
              </p>
              <p className="outStoryContentText">
                - Communicating with customers regarding their orders,
                inquiries, or customer support requests.
              </p>
            </div>
            <div className="mb-2">
              <p className="outStoryContent header-name mb-1">
                2.2 Marketing and Communications:
              </p>
              <p className="outStoryContentText">
                - Sending promotional emails or newsletters about our products,
                offers, or events.
              </p>
              <p className="outStoryContentText">
                - Providing information about updates, changes, or enhancements
                to our services.
              </p>
            </div>
          </div>
          <div>
            <p className="outStoryContent fw-bold mb-2">
              3. Information Sharing and Disclosure:
            </p>
            <div className="mb-2">
              <p className="outStoryContent header-name mb-1">
                3.1 Service Providers:
              </p>
              <p className="outStoryContentText">
                - Engaging trusted third-party service providers to assist us in
                delivering our services, subject to their agreement to protect
                the confidentiality and security of your information.
              </p>
            </div>
            <div className="mb-2">
              <p className="outStoryContent header-name mb-1">
                3.2 Legal Requirements:
              </p>
              <p className="outStoryContentText">
                - Complying with applicable laws, regulations, or legal
                processes.
              </p>
              <p className="outStoryContentText">
                - Enforcing our rights, protecting our customers, or
                investigating potential fraud or security breaches.
              </p>
            </div>
          </div>
          <div>
            <p className="outStoryContent fw-bold mb-2">4. Data Security:</p>
            <div className="mb-2">
              <p className="outStoryContentText">
                - We implement reasonable security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, or destruction. However, please note that no method
                of transmission over the internet or electronic storage is
                completely secure.
              </p>
            </div>
          </div>
          <div>
            <p className="outStoryContent fw-bold mb-2">
              5. Third-Party Links:
            </p>
            <div className="mb-2">
              <p className="outStoryContentText">
                - Our website or services may contain links to third-party
                websites or services. We are not responsible for the privacy
                practices or content of such third-party websites. We encourage
                our customers to review the privacy policies of these websites
                before providing any personal information.
              </p>
            </div>
          </div>
          <div>
            <p className="outStoryContent fw-bold mb-2">6. Contact Us:</p>
            <div className="mb-2">
              <p className="outStoryContentText">
                - If you have any questions, concerns, or requests regarding
                this Privacy Policy or our privacy practices, please contact us
                at drjacks@obu.edu. By using our website, services, or visiting
                our coffee shop, you acknowledge that you have read and
                understood this Privacy Policy and agree to the collection, use,
                and disclosure of your information as described herein.
              </p>
            </div>
          </div>
          {/* <p className="outStoryContent">
            We may collect personal information (such as email address, delivery
            address, name, phone number, credit card/debit card and other
            payment instrument details) from you when you set up an account or
            transact with us or participate in any event or contest. While you
            can browse some sections of our Platform without being a registered
            member, certain activities (such as placing an order or consuming
            our online content or services) do require registration. We use your
            contact information to send you offers based on your previous orders
            and your interests.
          </p> */}
        </div>
      </div>
    </section>
  );
};
