import React, { useEffect, useState } from "react";
import { AddReview } from "../../redux/action/AddReviewAction";
import { useParams, useNavigate } from "react-router-dom";
import "./review.css";
import { toast } from "react-toastify";
import { CircularProgress } from "../../components/CommonLoader";

export const Review = () => {
  const { product_id } = useParams();
  const navigate = useNavigate();
  const [selectedRating, setSelectedRating] = useState(0);
  const [review, setReview] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    rating: "",
    comment: "",
  });

  const rate = (stars) => {
    setSelectedRating(stars);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess, navigate]);

  const submitReview = async () => {
    const reviewData = {
      product: product_id,
      rating: selectedRating,
      comment: review,
    };

    if (!selectedRating) {
      setError((prevError) => ({
        ...prevError,
        rating: "Please provide a rating.",
      }));
      return;
    } else {
      setError((prevError) => ({
        ...prevError,
        rating: "",
      }));
    }

    if (!review.trim()) {
      setError((prevError) => ({
        ...prevError,
        comment: "Please provide a comment.",
      }));
      return;
    } else {
      setError((prevError) => ({
        ...prevError,
        comment: "",
      }));
    }

    try {
      setLoading(true);
      const response = await AddReview(reviewData);

      if (response?.isSuccess) {
        toast.success("Review submitted successfully!");
        setIsSuccess(true);
      } else {
        toast.error(response?.displayMsg || "Failed to submit review.");
        setIsSuccess(false);
      }
    } catch (error) {
      console.log("Error in adding review:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <div
          className="auto-container text-center position-relative z-1"
          style={{ width: "100%" }}
        >
          <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
            <div className="row clearfix d-flex justify-content-between">
              <div
                className="back-arrow d-flex align-items-center mt-4"
                onClick={handleGoBack}
                style={{ width: "40px" }}
              >
                <i
                  className="bi bi-arrow-left"
                  style={{ fontSize: "30px" }}
                ></i>
              </div>
              <div
                className="title-column"
                style={{ textAlign: "center", width: "250px" }}
              >
                <h2>Add Review</h2>
              </div>
            </div>
          </div>

          <div className="rating-container" style={{ cursor: "pointer" }}>
            {[1, 2, 3, 4, 5].map((stars) => (
              <span
                key={stars}
                className="star"
                onClick={() => rate(stars)}
                style={{ color: stars <= selectedRating ? "#ffd700" : "#ccc" }}
              >
                ★
              </span>
            ))}
          </div>

          <div className="review-container">
            <textarea
              id="review"
              rows="4"
              placeholder="Write your review here..."
              value={review}
              onChange={(e) => setReview(e.target.value)}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px",
                boxSizing: "border-box",
                margin: "15px",
                width: "50%",
              }}
            ></textarea>
          </div>
          <button className="submit-btn" onClick={submitReview}>
            Submit Review
          </button>
          {error.rating && <div style={{ color: "red" }}>{error.rating}</div>}
          {error.comment && <div style={{ color: "red" }}>{error.comment}</div>}
        </div>
      )}
    </>
  );
};
