import React, { useEffect } from "react";
import Partner1 from "../../assets/images/partner/1.jpg";
import { Link } from "react-router-dom";
import { GetVendorAction } from "../../redux/action/GetVendorAction";
import { useDispatch, useSelector } from "react-redux";
import { LoaderSvg } from "../../assets/AllSvgs";
// import { imageURL } from "../../utils/constant";
import "./partner.css";

export const Partner = () => {
  const dispatch = useDispatch();
  const imageURL = process.env.REACT_APP_IMAGE_URL;

  const getAllVendor = useSelector(
    (state) => state?.getAllVendor?.vendorGetAllData
  );
  const fetching = useSelector((state) => state.getAllVendor.fetching);

  useEffect(() => {
    dispatch(GetVendorAction());
  }, []);

  return (
    <section className="awards-section">
      <div className="container">
        <div className="sec-title style-two">
          <div className="title-column">
            <h2>Our Partners</h2>
          </div>
        </div>

        <div className="row clearfix">
          {fetching ? (
            <LoaderSvg width={50} height={50} />
          ) : (
            <>
              {getAllVendor?.result?.length ? (
                <>
                  {getAllVendor?.result?.map((data, index) => {
                    return (
                      <div
                        className="award-block col-lg-3 col-md-3 col-sm-12 wow fadeIn animated vendor-box"
                        style={{
                          visibility: "visible",
                          animationName: "fadeIn",
                        }}
                      >
                        <Link
                          to={data?.website}
                          target="_blank"
                          className="d-block"
                        >
                          <div className="inner-box vender">
                            <div className="image">
                              <img
                                src={
                                  data.image
                                    ? imageURL + data.image[0]
                                    : Partner1
                                }
                                crossOrigin="anonymous"
                                alt="partner"
                                className="w-100 h-100 object-fit-cover"
                              />
                            </div>
                            <div className="caption-box mt-2">
                              <h3 className="categoryName">
                                {data?.first_name + " " + data?.last_name}
                              </h3>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="no-products-found">
                  <p>No products found</p>
                </div>
              )}
            </>
          )}
          {/* 
          <div
            className="award-block col-lg-3 col-md-3 col-sm-12 wow fadeIn animated"
            style={{ visibility: "visible", animationName: "fadeIn" }}
          >
            <div className="inner-box">
              <Link
                to="https://drinkcoffeesharehope.com/pages/about-us"
                target="_blank"
              >
                {" "}
                <figure className="image">
                  <img src={Partner2} alt="" />
                </figure>
              </Link>
            </div>
          </div>
          <div
            className="award-block col-lg-3 col-md-3 col-sm-12 wow fadeIn animated"
            style={{ visibility: "visible", animationName: "fadeIn" }}
          >
            <div className="inner-box">
              <Link
                to="https://www.beardedsergeant.com/pages/our-story"
                target="_blank"
              >
                {" "}
                <figure className="image">
                  <img src={Partner3} alt="" />
                </figure>
              </Link>
            </div>
          </div>
          <div
            className="award-block col-lg-3 col-md-3 col-sm-12 wow fadeIn animated"
            style={{ visibility: "visible", animationName: "fadeIn" }}
          >
            <div className="inner-box">
              <Link
                to="https://www.leivascoffee.com/pages/join-our-mission"
                target="_blank"
              >
                {" "}
                <figure className="image">
                  <img src={Partner4} alt="" />
                </figure>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
