import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import { GetAllProductAction } from "../../redux/action/GetProductAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-js-loader";
import SearchFilter from "../../components/SearchFilter";
import { getUserId } from "../../utils/Storage";
import ProductCard from "../../components/ProductCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderSvg } from "../../assets/AllSvgs";
import debounce from "lodash/debounce";

export const Product = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState("atoz");
  const [loaderPage, setLoaderPage] = useState(false);
  const userId = getUserId();
  const imageURL = process.env.REACT_APP_IMAGE_URL;

  const GetAllProduct = (search, pageNumber, filters) =>
    dispatch(
      GetAllProductAction(search, pageNumber, filters, userId, () =>
        setLoaderPage(false)
      )
    );

  const getAllProductState = useSelector(
    (state) => state?.getAllProduct?.productGetAllData
  );
  const fetching = useSelector((state) => state.getAllProduct.fetching);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await GetAllProduct(search, page, filters);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [search, filters, page]);

  const handleSearchChange = debounce((value) => {
    setSearch(value);
    setPage(1);
  }, 1000);

  return (
    <>
      <section
        className="services-section position-relative z-1"
        style={{ paddingTop: "50px" }}
      >
        <div className="container">
          <div className="sec-title style-two">
            <div className="row clearfix">
              <div className="title-column col-lg-12 col-md-12 col-sm-12">
                <h2>
                  Our <span>Products</span>
                </h2>
              </div>
            </div>
          </div>
          <SearchFilter
            setFilters={setFilters}
            setSearch={(value) => handleSearchChange(value)}
            setPage={setPage}
          />
          {fetching && !loaderPage ? (
            <Loader />
          ) : (
            <InfiniteScroll
              className="hide-scroll"
              dataLength={getAllProductState?.result?.length ?? 0}
              next={() => {
                if (getAllProductState?.meta?.nextPage) {
                  setLoaderPage(true);
                  setPage(page + 1);
                }
              }}
              hasMore={getAllProductState?.meta?.nextPage}
              loader={<LoaderSvg width={50} height={50} />}
            >
              <div className="row clearfix">
                {getAllProductState?.result?.map((product, index) => (
                  <ProductCard
                    cardClassName="col-xl-3 col-lg-4 col-md-6 col-12"
                    imageUrl={`${imageURL}${product.image[0]}`}
                    key={product._id}
                    productDescription={product.description}
                    productId={product._id}
                    productPrice={product.total_price}
                    productTitle={product.title}
                    productBean={product.bean}
                    isAddedByAdmin={product.isAddedByAdmin}
                    vendorName={`${
                      product.vendorDetails?.first_name ??
                      (product.isAddedByAdmin ? "Dr Jack's" : "Vendor")
                    } ${product.vendorDetails?.last_name ?? ""}`}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}
          {getAllProductState?.meta?.totalCount ===
            getAllProductState?.result?.length &&
            getAllProductState?.result?.length > 0 && (
              <p className="mt-4" style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            )}
          {getAllProductState?.result?.length === 0 && (
            <div className="no-products-found">
              <p>No products found</p>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
