import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";
import * as actions from "./index";

export const GetCartListAction = () => (dispatch) => {
  const token = getToken();
  if (!token) {
    dispatch(actions.getCartError("No token found"));
    return;
  }
  
  dispatch(actions.getCartRequest());
  apiInstance
    .get("cart/get", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(actions.getCartSuccess(result.data.data));
    })
    .catch((error) => {
      dispatch(actions.getCartError(error?.response?.message));
    });
};
