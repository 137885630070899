import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { DeleteCartDataAction } from "../../redux/action/DeleteCartDataAction";
import { VerifyPaymentAction } from "../../redux/action/VerifyPaymentAction";
import { GetCartListAction } from "../../redux/action/GetCartListAction";
import { RotatingLines } from "../../components/CommonLoader";
import { UpdateOrder } from "../../redux/action/UpdateOrderAction";

const CheckoutForm = ({
  clientSecret,
  setShowModal,
  orderId,
  newAddress,
  billingAddress,
  sameAsShipping,
  newAddError,
  getCheckDataState,
  userDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Loader, setLoader] = useState(false);

  const GetCartList = () => dispatch(GetCartListAction());

  const Items = getCheckDataState?.cartAccountingList?.map((item) => {
    return item?.products?.map((el) => ({
      productId: el.productId,
      productName: el.productName,
      unitPrice: el.unitPrice,
      totalPrice: el.totalPrice,
      quantity: el.quantity,
    }));
  });

  const customer = {
    email: userDetails?.email,
    name: ` ${userDetails?.first_name} ${userDetails?.last_name}`,
    item: Items,
    phone: userDetails?.phone_number,
    address: {
      line1: userDetails?.address,
      city: userDetails?.city,
      country: userDetails?.country,
      postal_code: userDetails?.pincode,
    },
  };

  const handleSubmit = async (event) => {
    setLoader(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: customer.name,
          email: customer.email,
          phone: customer.phone,
          address: {
            line1: customer.address.line1,
            city: customer.address.city,
            country: customer.address.country,
            postal_code: customer.address.postal_code,
          },
        },
        metadata: {
          items: JSON.stringify(customer.item),
        },
      },
      receipt_email: customer.email,
    });

    if (result.error) {
      setLoader(false);
      toast.error(result.error.message);
      const paymentStatus =
        result?.error?.payment_intent?.status === "requires_payment_method"
          ? "PF"
          : "TC";
      const req = {
        order_id: orderId,
        status: paymentStatus,
        payment_status: paymentStatus,
        reason: result.error.message,
      };
      await UpdateOrder(req);
      if (result.error.code === "card_declined") {
        navigate("/cart");
      }
    } else {
      const billing = sameAsShipping ? newAddress : billingAddress;

      await VerifyPaymentAction({
        paymentId: result?.paymentIntent?.id,
        order_id: orderId,
        shippingAddress: newAddress,
        billingAddress: billing,
        userDetails: userDetails,
      });
      await DeleteCartDataAction();
      toast.success("Order placed successfully!");
      GetCartList();
      setShowModal(false);
      setLoader(false);
      navigate("/order-confirmed", {
        state: {
          paymentData: result,
          order_id: orderId,
          getCheckDataState: getCheckDataState,
        },
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement options={{ hidePostalCode: true }} />
        <button
          type="submit"
          className="btn btn-pay btn-primary mt-3"
          disabled={!stripe || !elements || newAddError}
        >
          {Loader ? <RotatingLines /> : "Pay"}
        </button>
      </form>
    </>
  );
};

export default CheckoutForm;
