import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";
import * as actions from "./index";

export const GetOrdersUserAction = (order_id) => (dispatch) => {
    const token = getToken()
    new Promise((resolve, reject) => {
        dispatch(actions.getUserOrderRequest());
        apiInstance
            .get(`order?order_id=${order_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((result) => {
                dispatch(actions.getUserOrderSuccess(result?.data?.data));
                resolve(result.data);
            })
            .catch((error) => {
                dispatch(actions.getUserOrderError(error?.response?.message));
                reject(error);
            });
    });
};