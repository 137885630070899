import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/style.css";
import "./productDetail.css";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddCartAction } from "../../redux/action/AddCartAction";
import { GetProductDetailAction } from "../../redux/action/GetProductDetailAction";
// import { imageURL } from "../../utils/constant";
// import { GetReviewDetailAction } from "../../redux/action/GetReviewDetailAction";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
import { GetCartListAction } from "../../redux/action/GetCartListAction";
import { toast } from "react-toastify";
import { getToken, getUserId } from "../../utils/Storage";
import { CircularProgress, RotatingLines } from "../../components/CommonLoader";

export const ProductDetail = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const { productId } = useParams();
  const navigate = useNavigate();
  const userId = getUserId();
  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const [addToCartButtonText, setAddToCartButtonText] = useState("Add to Cart");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [beanOption, setBeanOption] = useState([]);
  const [bean, setBean] = useState(null);

  const mainSwiperRef = useRef(null);
  const thumbsSwiperRef = useRef(null);
  const secondSwiperRef = useRef(null);

  const MIN_QUANTITY = 1;
  const MAX_QUANTITY = 10;

  const addQty = () => {
    if (count < MAX_QUANTITY) {
      setCount(count + 1);
    }
  };

  const RemoveQty = () => {
    if (count > MIN_QUANTITY) {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    checkLoginStatus();
    const fetchData = async () => {
      try {
        dispatch(GetProductDetailAction(productId, userId));
        // dispatch(GetReviewDetailAction(productId));
      } catch (error) {
        console.error("Error fetching product details", error);
      }
    };

    fetchData();
  }, [dispatch, productId]);

  const handleImageClick = (index) => {
    secondSwiperRef?.current?.slideTo(index);
  };

  const productDetail = useSelector(
    (state) => state.getProductDetail.productDetails
  );

  useEffect(() => {
    if (productDetail?.bean) {
      let data = productDetail?.bean?.map((x) => ({ value: x, label: x }));
      setBeanOption(data);
    }
  }, [productDetail]);

  const fetching = useSelector((state) => state.getProductDetail.fetching);

  const productReviews = useSelector(
    (state) => state.getReviewDetail.reviewDetail
  );

  const displaySuccessMessage = (message) => {
    setSuccessMessage(message);
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 1000);
  };

  const checkLoginStatus = () => {
    setIsLoggedIn(!!getToken());
  };

  const handleAddToCart = async () => {
    try {
      setIsAddingToCart(true);
      const res = {
        product_id: productDetail._id,
        quantity: count,
        bean: bean ?? beanOption[0]?.value,
      };

      if (!isLoggedIn) {
        toast.error("Please log in to add items to your cart");
        navigate("/login", {
          state: {
            url: `/productDetail/${productDetail._id}`,
          },
        });
      } else {
        await AddCartAction(res);
        dispatch(GetCartListAction());
        displaySuccessMessage("Item added to cart successfully!");
        setAddToCartButtonText("Go to Cart");
      }
    } catch (error) {
      console.error("Error adding item to review", error);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const handleGoToCart = () => {
    if (addToCartButtonText === "Go to Cart") {
      navigate("/cart");
    } else {
      handleAddToCart();
    }
  };

  // const handleAddReview = async () => {
  //   try {
  //     const res = {
  //       product_id: productDetail._id,
  //     };
  //     if (res) {
  //       navigate(`/review/${productDetail._id}`);
  //     } else {
  //       console.log("Failed to add item to review");
  //     }
  //   } catch (error) {
  //     console.error("Error adding item to review", error);
  //   } finally {
  //   }
  // };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {fetching ? (
        <CircularProgress />
      ) : (
        <>
          <div className="sidebar-page-container container z-1 position-relative z-1">
            <div
              className="back-arrow d-flex align-items-center m-4 mt-0"
              onClick={handleGoBack}
            >
              <i className="bi bi-arrow-left" style={{ fontSize: "30px" }}></i>
            </div>
            <div className="auto-container">
              <div className="row clearfix">
                <div className="content-side col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="shop-single">
                    <div className="product-details">
                      <div className="basic-details">
                        <div className="row clearfix">
                          <div className="image-column col-lg-4 col-md-4 col-sm-12">
                            <div className="ms-2">
                              {productDetail?.image &&
                              productDetail?.image.length > 0 ? (
                                <Swiper
                                  autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                  }}
                                  onSwiper={(swiper) => {
                                    secondSwiperRef.current = swiper;
                                  }}
                                  loop={true}
                                  spaceBetween={10}
                                  navigation={false}
                                  modules={[
                                    Autoplay,
                                    FreeMode,
                                    Navigation,
                                    Thumbs,
                                  ]}
                                  className="mySwiper2 producDetailsImag"
                                >
                                  {productDetail?.image &&
                                    productDetail?.image.length > 0 &&
                                    productDetail.image.map((image, index) => (
                                      <>
                                        <SwiperSlide
                                          key={index}
                                          className="flex"
                                        >
                                          <div className="swiper_img">
                                            <img
                                              src={`${imageURL + image}`}
                                              crossOrigin="anonymous"
                                              className="w-100 h-100 object-fit-cover object-position-center"
                                            />
                                          </div>
                                        </SwiperSlide>
                                      </>
                                    ))}
                                </Swiper>
                              ) : (
                                <img
                                  src="../../assets/images/banner1-1.jpg"
                                  alt="Static Image"
                                  className="imgLg"
                                />
                              )}
                            </div>
                            <div className="m-2 d-flex gap-1">
                              {productDetail?.image &&
                                productDetail?.image.length > 0 && (
                                  <Swiper
                                    onSwiper={(swiper) => {
                                      mainSwiperRef.current = swiper;
                                    }}
                                    loop={true}
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    navigation={false}
                                    thumbs={{ swiper: thumbsSwiperRef.current }}
                                    className="mySwiper"
                                  >
                                    {productDetail.image.map((image, index) => (
                                      <SwiperSlide
                                        key={index}
                                        className="flex slide2"
                                      >
                                        <img
                                          src={`${imageURL + image}`}
                                          crossOrigin="anonymous"
                                          className="img"
                                          onClick={() =>
                                            handleImageClick(index)
                                          }
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                )}
                            </div>
                          </div>
                          <div className="info-column col-lg-8 col-md-8 col-sm-12">
                            <div className="details-header ">
                              <h4>
                                <b>{productDetail?.title}</b>
                              </h4>
                              <table id="customers">
                                <tbody>
                                  <tr>
                                    <th className="pro-title">Vendor </th>
                                    <td className="pro-text">
                                      :{" "}
                                      {productDetail?.vendorDetails?.first_name}{" "}
                                      {productDetail?.vendorDetails?.last_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pro-title">Weight </th>
                                    <td className="pro-text">
                                      : {productDetail?.weight}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pro-title">Origins </th>
                                    <td className="pro-text">
                                      : {productDetail?.origins}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pro-title">Roast </th>
                                    <td className="pro-text">
                                      : {productDetail?.roast}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <td className="fs-3 fw-bold">
                                $
                                {productDetail?.total_price
                                  ? Number(productDetail?.total_price).toFixed(2)
                                  : 0}
                              </td>
                            </div>
                            <label className="pro-title">Bean:</label>
                            <div className="d-flex gap-2 mb-3">
                              {beanOption?.map((e, index) => {
                                return (
                                  <div
                                    className="bean_box"
                                    onClick={() => {
                                      setBean(e.value);
                                      setAddToCartButtonText("Add to Cart");
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name="bean"
                                      value={e.value}
                                      className="bean_input w-100 h-100"
                                      checked={
                                        bean ? bean === e.value : index === 0
                                      }
                                    />
                                    <div className="bean_label border w-100 h-100">
                                      {e.label}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="mb-3 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                              {" "}
                              <div className="d-flex justify-content-around align-items-center qtySelectionP border border-black rounded-pill">
                                <button className="btn" onClick={RemoveQty}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                  >
                                    <rect
                                      x="22"
                                      y="10"
                                      width="2"
                                      height="22"
                                      rx="1"
                                      transform="rotate(90 22 10)"
                                      fill="black"
                                    />
                                  </svg>
                                </button>
                                <p className="qtyCount">{count}</p>
                                <button className="btn" onClick={addQty}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                  >
                                    <rect
                                      x="22"
                                      y="10"
                                      width="2"
                                      height="22"
                                      rx="1"
                                      transform="rotate(90 22 10)"
                                      fill="black"
                                    />
                                    <rect
                                      x="10"
                                      width="2"
                                      height="22"
                                      rx="1"
                                      fill="black"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div className="text-dec">
                              DESCRIPTION :{" "}
                              <p style={{ fontSize: "17px" }}>
                                {" "}
                                {productDetail?.description}{" "}
                              </p>
                            </div>
                            {isSuccess && (
                              <div style={{ color: "green" }}>
                                {successMessage}
                              </div>
                            )}
                            <div className="buttons-container">
                              <button
                                className="add-to-cart-btn btn"
                                onClick={handleGoToCart}
                                disabled={isAddingToCart}
                              >
                                {isAddingToCart ? (
                                  <RotatingLines />
                                ) : (
                                  addToCartButtonText
                                )}
                              </button>
                              {/* {isLoggedIn && productDetail?.canAddReview ? (
                                <button
                                  className="add-to-cart-btn btn"
                                  onClick={handleAddReview}
                                >
                                  Add Review{" "}
                                </button>
                              ) : null} */}
                            </div>
                            {/* <div className="text-dec mt-4">
                              Product Reviews :
                              {productReviews && productReviews?.length > 0 ? (
                                <div>
                                  {productReviews?.map((review) => (
                                    <li key={review._id}>
                                      <p className="m-0">
                                        {" "}
                                        <strong>
                                          {review?.user?.first_name}{" "}
                                          {review?.user?.last_name}
                                        </strong>{" "}
                                      </p>
                                      <p className="m-0">
                                        Rating: {review?.rating}, comment:{" "}
                                        {review?.comment}
                                      </p>
                                    </li>
                                  ))}
                                </div>
                              ) : (
                                <p>
                                  No reviews available. Be the first to add a
                                  review!
                                </p>
                              )}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
