import React, { useEffect } from "react";
import "../../assets/css/style.css";
import backgroundImage from "../../assets/images/banner2.jpg";
import backgroundImage1 from "../../assets/images/banner1.jpg";
import bgStory from "../../assets/images/bg-story.png";
import "./home.css";
import { Product } from "../product/product";
import { Partner } from "../partner/partner";
import { Link } from "react-router-dom";
import { Category } from "../category/Category";
import { useDispatch } from "react-redux";
import { GetCartListAction } from "../../redux/action/GetCartListAction";
import { Banner } from "../../assets/images";

export const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetCartListAction());
  }, []);

  return (
    <>
      {/* Banner Section */}
      <section className="banner-section z-1">
        <div
          id="carouselExampleIndicators"
          className="carousel slide banner-carousel"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <span className="banner-black"></span>
              <img src={Banner} className="d-block w-100" alt="Banner 1" />
              <div className="carousel-caption d-none d-md-block h-100">
                <div className="banner-texts">
                  <div className="content-box">
                    <h2 style={{ color: "#160f04" }}>
                      Dr. JACK'S
                      <br />
                      <span className="whitespace-nowrap"> COFFEE HOUSE </span>
                    </h2>
                    <h4 style={{ color: "#322a13" }}>
                      Coffee with a <br /> purpose
                    </h4>
                    <div className="link-box clearfix">
                      <Link to="#" className="theme-btn "></Link>
                      <Link to="#" className="link"></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <span className="banner-black"></span>
              <img src={Banner} className="d-block w-100" alt="Banner 2" />
              <div className="carousel-caption d-none d-md-block h-100">
                <div className="banner-texts">
                  <div className="content-box">
                    <h2 style={{ color: "#160f04" }}>
                      Dr. JACK'S
                      <br />
                      <span className="whitespace-nowrap"> COFFEE HOUSE </span>
                    </h2>
                    <h4 style={{ color: "#322a13" }}>
                      Coffee with a <br /> purpose
                    </h4>
                    <div className="link-box clearfix">
                      <Link to="#" className="theme-btn "></Link>
                      <Link to="#" className="link"></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <div className="prev-icon">
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </div>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <div className="prev-icon">
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </div>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      {/* End Banner Section */}
      <section
        className="story-section"
        style={{
          backgroundImage: `url(${bgStory})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
            <div className="row clearfix">
              <div className="title-column" style={{ textAlign: "center" }}>
                <h2>Our Story</h2>
              </div>
            </div>
          </div>
          <div className="wow fadeIn animated">
            <p className="outStoryContent">
              <span className="outStortContentParagraph"> In 2013 </span> ,
              business students from Hickingbotham Hall at Ouachita Baptist University celebrated a winning business competition entry: Dr. Jack’s Coffeehouse. Named after the university’s first president, John “Jack” Conger, 
              <Link to="about" className="read-more">
                read more...
              </Link>
            </p>
          </div>
        </div>
      </section>
      <Category />
      <Product />
      <Partner />
    </>
  );
};
