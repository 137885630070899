import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";

export const AddReview = async (reviewData) => {
  const token = getToken();
  try {
    const res = await apiInstance.post("review/add", reviewData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }

    return null;
  } catch (err) {
    console.error('AddReview Error:', err);
    return err;
  }
};