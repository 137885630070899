import { apiInstance } from "../../httpclient/index";

export const ForgotPassword = async (data, setForgotPasswordLoading) => {
  setForgotPasswordLoading(true);
  try {
    const res = await apiInstance.post("user/forgotPassword", data);
    setForgotPasswordLoading(false);
    if (res.status === 200) {
      return res.data;
    }
    return null;
  } catch (err) {
    setForgotPasswordLoading(false);
    return err.response;
  }
};
