import React, { useCallback, useState } from "react";
import "./ConnectUs.css";
import { EMAIL_REGEX } from "../../utils/constant";
import { SendMessageAction } from "../../redux/action/SendMessageAction";
import { toast } from "react-toastify";
import { Row } from "react-bootstrap";
import { RotatingLines } from "../../components/CommonLoader";

export const ConnectUs = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setNameError("");
    setEmailError("");

    if (!(data.name.length > 2)) {
      setNameError("* Name is required");
      return;
    }

    if (!data.email.length) {
      setEmailError("* Email is required");
      return;
    }

    if (!EMAIL_REGEX.test(data.email)) {
      setEmailError("* Invalid Email");
      return;
    }

    if (!data.message.length) {
      setMessageError("* Message is required");
      return;
    }

    try {
      const payload = {
        name: data?.name,
        email: data?.email,
        message: data?.message,
      };
      setLoading(true);
      const response = await SendMessageAction(payload);
      setLoading(false);
      if (response?.isSuccess) {
        toast.success(response?.displayMsg);
        setData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleFormData = useCallback(
    (e) => {
      const { name, value } = e.target;
      setData((prevData) => ({ ...prevData, [name]: value }));
      switch (name) {
        case "name":
          setNameError(value.length >= 2 ? "" : "* Name is required");
          break;
        case "email":
          setEmailError(value.length > 0 ? "" : "* Email is required");
          break;
        case "message":
          setMessageError(value.length > 0 ? "" : "* Message is required");
          break;
        default:
          break;
      }
    },
    []
  );

  return (
    <div style={{ backgroundColor: "#f4f2f0" }}>
      <div className="container connect-us">
        <div className="connect-us-container z-1">
          <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
            <div className="row clearfix">
              <div className="title-column col-lg-12 col-md-12 col-sm-12">
                <h2>Send us a message</h2>
              </div>
            </div>
          </div>
          <p>
            Trouble ordering products or any other queries?
          </p>
          <p>
            Kindly drop us a message, and we'll be more than happy to help you.
          </p>
          <Row>
            <div className="col-6">
              <div>
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  value={data.name}
                  onChange={handleFormData}
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                />
                {nameError && <p className="text-danger mb-0">{nameError}</p>}
              </div>

              <div>
                <label htmlFor="email">Email:</label>
                <input
                  value={data.email}
                  className="form-control"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleFormData}
                />
                {emailError && <p className="text-danger mb-0">{emailError}</p>}
              </div>

              <div>
                <label htmlFor="message">Message:</label>
                <textarea
                  value={data.message}
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder="Message"
                  onChange={handleFormData}
                ></textarea>
                {messageError && (
                  <p className="text-danger mb-0">{messageError}</p>
                )}
              </div>
              <button className="submit" type="submit" onClick={handleSubmit}>
                {loading ? (
                  <RotatingLines />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};
