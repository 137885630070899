import { apiInstance } from "../../httpclient";
import * as actions from "./index";

export const GetProductDetailAction = (Product_id, userId) => (dispatch) => {
  new Promise((resolve, reject) => {
    dispatch(actions.getProductDetailRequest());
    apiInstance
      .get(`product?product_id=${Product_id}&userId=${userId ?? ""}`)
      .then((result) => {
        dispatch(actions.getProductDetailSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.getProductDetailError(error.response.message));
        reject(error);
      });
  });
};
