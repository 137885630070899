// export const baseURL = "https://api.drjacks.coffee/api/v1/app/";
// export const imageURL = "https://api.drjacks.coffee/uploads/";
// export const baseURL = "http://192.168.0.172:8000/api/v1/app/";
// export const baseURL = "http://localhost:8000/api/v1/app/";
// export const imageURL = "http://localhost:8000/uploads/";

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const GET_USER_DETAIL_REQUEST = "GET_USER_DETAIL_REQUEST";
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const GET_USER_DETAIL_ERROR = "GET_USER_DETAIL_ERROR";

export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_ERROR = "GET_CART_ERROR";

export const ADD_CHECKOUT_REQUEST = "ADD_CHECKOUT_REQUEST";
export const ADD_CHECKOUT_SUCCESS = "ADD_CHECKOUT_SUCCESS";
export const ADD_CHECKOUT_ERROR = "ADD_CHECKOUT_FAILURE";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const GET_VENDOR_REQUEST = "GET_VENDOR_REQUEST";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_ERROR = "GET_VENDOR_ERROR";

export const GET_REVIEW_REQUEST = "GET_REVIEW_REQUEST";
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const GET_REVIEW_ERROR = "GET_REVIEW_ERROR";

export const GET_REVIEW_DETAIL_REQUEST = "GET_REVIEW_DETAIL_REQUEST";
export const GET_REVIEW_DETAIL_SUCCESS = "GET_REVIEW_DETAIL_SUCCESS";
export const GET_REVIEW_DETAIL_ERROR = "GET_REVIEW_DETAIL_ERROR";

export const SET_USER_DETAIL_REQUEST = "SET_USER_DETAIL_REQUEST";
export const SET_USER_DETAIL_SUCCESS = "SET_USER_DETAIL_SUCCESS";
export const SET_USER_DETAIL_ERROR = "SET_USER_DETAIL_ERROR";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const GET_ORDER_DETAIL_REQUEST = "GET_ORDER_DETAIL_REQUEST";
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";
export const GET_ORDER_DETAIL_ERROR = "GET_ORDER_DETAIL_ERROR";

export const GET_PRODUCT_DETAIL_REQUEST = "GET_PRODUCT_DETAIL_REQUEST";
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS";
export const GET_PRODUCT_DETAIL_ERROR = "GET_PRODUCT_DETAIL_ERROR";

export const GET_PRODUCT_IMAGE_REQUEST = "GET_PRODUCT_IMAGE_REQUEST";
export const GET_PRODUCT_IMAGE_SUCCESS = "GET_PRODUCT_IMAGE_SUCCESS";
export const GET_PRODUCT_IMAGE_ERROR = "GET_PRODUCT_IMAGE_ERROR";

export const GET_USER_ORDER_REQUEST = "GET_USER_ORDER_REQUEST";
export const GET_USER_ORDER_SUCCESS = "GET_USER_ORDER_SUCCESS";
export const GET_USER_ORDER_ERROR = "GET_USER_ORDER_ERROR";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";

export const GET_CATEGORY_DETAIL_REQUEST = "GET_CATEGORY_DETAIL_REQUEST";
export const GET_CATEGORY_DETAIL_SUCCESS = "GET_CATEGORY_DETAIL_SUCCESS";
export const GET_CATEGORY_DETAIL_ERROR = "GET_CATEGORY_DETAIL_ERROR";
