import React, { useState } from "react";
import "./faqStyle.css";
import { Link } from "react-router-dom";

export const FaqScreen = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activePaymentIndex, setActivePaymentIndex] = useState(null);

  const orderQuestions = [
    {
      question: "How can I order?",
      answer: (
        <>
          You can order in our online shop. To do this you{" "}
          <Link
            to="/signUp"
            className="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
          >
            create an account
          </Link>{" "}
          and then you can happily go shopping.
        </>
      ),
    },
    {
      question: "I ordered, what happens then?",
      answer:
        "Your order will be processed and shipped within 1-3 business days. You will receive an email with a tracking number once your order has shipped.",
    },
    {
      question: "Is there a minimum order?",
      answer: "No, there is no minimum order.",
    },
  ];

  const paymentQuestions = [
    {
      question: "How can i pay?",
      answer: "You can pay with card or cash",
    },
    {
      question: "When do i have to Pay?",
      answer: "You can pay with card or cash",
    },
  ];

  const OrderQuestion = ({ items }) => {
    const handleClick = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };

    return (
      <div className="accordion position-relative z-1" id="faqAccordion">
        {items.map((item, index) => (
          <div key={index} className="accordion-item ">
            <h4
              className="accordion-header "
              id={`question-${index}`}
              onClick={() => handleClick(index)}
            >
              <button
                className={`accordion-button shadow-sm  ${
                  activeIndex === index ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#answer-${index}`}
                aria-expanded={activeIndex === index ? "true" : "false"}
                aria-controls={`answer-${index}`}
              >
                {item.question}
              </button>
            </h4>
            <div
              id={`answer-${index}`}
              className={`accordion-collapse shadow-sm collapse ${
                activeIndex === index ? "show" : ""
              }`}
              aria-labelledby={`question-${index}`}
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">{item.answer}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const PaymentQuestion = ({ items }) => {
    const handleClick = (index) => {
      setActivePaymentIndex(index === activePaymentIndex ? null : index);
    };

    return (
      <div className="accordion " id="faqAccordion">
        {items.map((item, index) => (
          <div key={index} className="accordion-item">
            <h4
              className="accordion-header"
              id={`question-${index}`}
              onClick={() => handleClick(index)}
            >
              <button
                className={`accordion-button ${
                  activePaymentIndex === index ? "" : "collapsed"
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#answer-${index}`}
                aria-expanded={activeIndex === index ? "true" : "false"}
                aria-controls={`answer-${index}`}
              >
                {item.question}
              </button>
            </h4>
            <div
              id={`answer-${index}`}
              className={`accordion-collapse collapse ${
                activePaymentIndex === index ? "show" : ""
              }`}
              aria-labelledby={`question-${index}`}
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">{item.answer}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ backgroundColor: "#f4f2f0" }}>
      <div className="faq container">
        <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
          <div className="row clearfix">
            <div
              className="title-column col-lg-12 col-md-12 col-sm-12"
              style={{ textAlign: "center" }}
            >
              <h2>Frequently Asked Qustions</h2>
            </div>
          </div>
        </div>
        <div className="faq-list">
          <h3 className="header-name">1. Order</h3>
          <OrderQuestion items={orderQuestions} />
          <h3 className="header-name">2. Payment</h3>
          <PaymentQuestion items={paymentQuestions} />
        </div>
      </div>
    </div>
  );
};
