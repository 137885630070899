import React, { useEffect, useState } from "react";
import "./successModal.css";
import Lottie from 'lottie-react';
import checkMark from './check-mark.json';
import { Link } from "react-router-dom";

const SuccessModal = ({ show, onClose }) => {
  const [animationStatus, setAnimationStatus] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: checkMark,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  useEffect(() => {
    if (show) {
      setAnimationStatus(true);
    }
  }, [show]);

  return (
    <div className={`success-modal ${show ? "active" : ""}`}>
      <div className="modal-content">
        <div className="modal-header">
          <h2>Order Confirmed!</h2>
          <button className="close-btn" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          <div className="animation-container d-flex justify-content-center align-items-center">
            <Lottie animationData={checkMark} play={animationStatus} options={defaultOptions} style={{
              height: '100px',
              width: '100px'
            }}
            />
          </div>
          <p>
            Your order was successfully placed and is being prepared for
            delivery.
          </p>
        </div>
        <Link className="btn btn-pay"  to={"/"}>
          Back To Store
        </Link>
      </div>
    </div>
  );
};

export default SuccessModal;