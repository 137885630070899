import { apiInstance } from "../../httpclient/index";

export const ResetPasswordAction = async (data, setResetPasswordLoading) => {
  setResetPasswordLoading(true);
  try {
    const res = await apiInstance.post("user/resetPassword", data);
    setResetPasswordLoading(false);
    if (res.status === 200) {
      return res.data;
    }
    return null;
  } catch (err) {
    setResetPasswordLoading(false);
    return err.response;
  }
};
