import React, { createContext, useEffect } from "react";
import { NavBar } from "./Navbar/navbar";
import { Footer } from "./Footer/footer";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUserId } from "../utils/Storage";
import { UserDetailsAction } from "../redux/action/GetUserDetail";

export const UserContext = createContext({});

export const DefaultLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = getUserId();
  const token = getToken();

  const userDetails = useSelector((state) => state.GetUserDetail.userGetData);

  const handleUserDetail = (id, navigate) =>
    dispatch(UserDetailsAction(id, navigate));

  useEffect(() => {
    if (userId && token) {
      handleUserDetail(userId, navigate);
    }
  }, []);

  return (
    <>
      <UserContext.Provider value={{ userDetails }}>
        <div className="d-flex flex-column min-vh-100">
          <NavBar />
          <div className="mx-auto w-100 flex-grow-1 bg-beans-sm">
            <Outlet />
          </div>
          <Footer />
        </div>
      </UserContext.Provider>
    </>
  );
};
