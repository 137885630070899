import React from "react";
import { RotatingLines as SpinnerRotatingLines } from "react-loader-spinner";
import Loader from "react-js-loader";

const loaderContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export function RotatingLines() {
  return (
    <div style={loaderContainerStyle}>
      <SpinnerRotatingLines
        strokeColor="#fff"
        strokeWidth="5"
        animationDuration="0.75"
        width="20"
        visible
      />
    </div>
  );
}

export function CircularProgress() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        color: "black",
      }}
    >
      <Loader />
    </div>
  );
}
