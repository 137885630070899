import React, { useContext, useEffect, useState } from "react";
import "./cart.css";
import { GetCartListAction } from "../../redux/action/GetCartListAction";
import { useDispatch, useSelector } from "react-redux";
import { DeleteCartDataAction } from "../../redux/action/DeleteCartDataAction";
import { getToken } from "../../utils/Storage";
import { AddCheckOutAction } from "../../redux/action/AddCheckOutAction";
import { useNavigate } from "react-router-dom";
import { Minus, Plus } from "../../assets/AllSvgs";
import { DeleteCartItem } from "../../redux/action/DeleteCartItem";
import { UserContext } from "../../components/DefaultLayout";
import { UpdateQuantity } from "../../redux/action/UpdateQuantity";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { CircularProgress, RotatingLines } from "../../components/CommonLoader";

export const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const userDetails = useContext(UserContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [allCount, setAllCount] = useState({});

  const getCartListState = useSelector(
    (state) => state?.getCartList?.cartGetAllData
  );

  const fetching = useSelector((state) => state.getCartList.fetching);

  const checkLoginStatus = () => {
    setIsLoggedIn(!!getToken());
  };

  useEffect(() => {
    checkLoginStatus();
    if (isLoggedIn) {
      dispatch(GetCartListAction());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (getCartListState) {
      let obj = {};
      getCartListState?.cartItems?.map((item) => {
        obj[item._id] = {
          amount: item?.finalPrice,
          orderCount: item?.quantity,
        };
      });
      setAllCount({ ...obj });
    }
  }, [getCartListState]);

  const CleanCart = async () => {
    swal({
      text: `Are you sure you want to clear all cart items?`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes, Confirm",
          value: true,
          visible: true,
          className: "btn-save",
          closeModal: true,
        },
      },
      dangerMode: false,
    }).then(async (willSave) => {
      if (willSave) {
        try {
          const cleanItem = JSON.parse(JSON.stringify(getCartListState));
          cleanItem.cartItems = cleanItem.cartItems.map((item) => {
            return {
              _id: item._id,
            };
          });
          const res = await DeleteCartDataAction(cleanItem);
          dispatch(GetCartListAction());
          if (res?.isSuccess) {
            toast.success(res?.displayMsg);
          }
        } catch (error) {
          console.log("Error Cancel order:", error);
        }
      }
    });
  };

  const deleteItem = async (item_id) => {
    try {
      await DeleteCartItem(item_id);
      dispatch(GetCartListAction());
    } catch (error) {
      console.error("Error deleting item from cart:", error);
    }
  };

  const proceedToBuy = async () => {
    if (getCartListState?.id) {
      const addData = { cartId: getCartListState?.id };
      setIsLoading(true);
      try {
        const response = await dispatch(AddCheckOutAction(addData));
        setIsLoading(false);
        if (response) {
          navigate("/checkout", {
            state: { cartId: getCartListState?.id, checkOutData: response },
          });
        } else {
          console.error("Checkout failed: No response data");
        }
      } catch (error) {
        console.error("Error during checkout:", error.message);
      }
    } else {
      console.error("Invalid cart ID");
    }
  };

  const renderLoginPrompt = () => (
    <div className="card loginCard h-100 position-relative z-1">
      <div className="loginPrompt">
        <p className="mb-4">Please log in to view your cart.</p>
        <button
          className="bg-theme text-white px-4 py-2 rounded-1"
          onClick={() => {
            navigate("/login", {
              state: {
                url: "/cart",
              },
            });
          }}
        >
          Login
        </button>
      </div>
    </div>
  );

  const editQty = async (id, quantity, bean) => {
    const req = {
      product_id: id,
      quantity: quantity,
      bean: bean,
    };
    await UpdateQuantity(req);
    dispatch(GetCartListAction());
  };

  const decrementCount = ({ id, amount, orderCount, bean }) => {
    let temp = { ...allCount };
    temp[id] = {
      count: (temp[id]?.count ?? orderCount) - 1,
      amount: (temp[id]?.amount ?? amount) - amount,
      bean: bean,
    };
    if (temp[id].count > 0) {
      setAllCount(temp);
      editQty(id, temp[id].count, bean);
    }
  };

  const incrementCount = ({ id, amount, orderCount, bean }) => {
    let temp = { ...allCount };
    temp[id] = {
      count: Math.min((temp[id]?.count ?? orderCount) + 1),
      amount: (temp[id]?.amount ?? amount) + amount,
      bean: bean,
    };
    if (temp[id]?.count < 10 || temp[id].count === 10) {
      editQty(id, temp[id].count, bean);
      setAllCount(temp);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const buttonStyle = {
    height: "40px",
    width: "110px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#332a16",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
  };

  const loaderContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const renderCartContent = () => (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative z-1 mb-3">
        <div className="d-flex">
          <div
            className="back-arrow d-flex align-items-center mt-3"
            onClick={handleGoBack}
            style={{ width: "40px" }}
          >
            <i className="bi bi-arrow-left" style={{ fontSize: "30px" }}></i>
          </div>
          <div className="yourCartTitle">
            <h2>Your Cart</h2>
          </div>
        </div>
        {getCartListState?.cartItems?.length > 0 && (
          <div className="d-flex justify-content-between">
            <div className="border border-black-2 rounded-pill d-flex deleteSection justify-content-center align-items-center ms-4">
              <button
                className="btn"
                onClick={CleanCart}
                title="Delete Cart data"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                >
                  <path
                    d="M2.5 6L2.85207 15.1537C2.93467 17.3015 4.69902 19 6.84835 19C8.15157 19 9.65679 19 11.1509 19C13.3002 19 15.0653 17.3015 15.1479 15.1537L15.5 6"
                    stroke="black"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.99997 2.91485L6.99997 2.91485C6.99997 2.91485 7.34514 1.21454 8.99997 1.21454C10.6548 1.21453 11 2.91486 11 2.91486L12 2.91486"
                    stroke="black"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11 12V14"
                    stroke="black"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                  <path
                    d="M7 12V14"
                    stroke="black"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                  <path
                    d="M17 3.70001L15.2761 3.54917C11.1 3.18375 6.89998 3.18375 2.72387 3.54917L1 3.70001"
                    stroke="black"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </div>
            <div>
              <button
                onClick={proceedToBuy}
                disabled={isLoading}
                style={buttonStyle}
                className="ms-3 mt-1"
              >
                {isLoading ? (
                  <div style={loaderContainerStyle}>
                    <RotatingLines />
                  </div>
                ) : (
                  "Checkout"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="divider" />
      {fetching ? (
        <CircularProgress />
      ) : getCartListState?.cartItems?.length > 0 ? (
        getCartListState?.cartItems?.map((item, index) => {
          const vendorName =
            item?.vendor?.first_name && item?.vendor?.last_name
              ? `${item.vendor.first_name} ${item.vendor.last_name}`
              : null;
          return (
            <>
              <div
                key={item?._id}
                className={`yourCartContainer${
                  index < getCartListState?.length - 1 ? " withBorder" : ""
                }`}
              >
                <div className="mainCartView">
                  <div className="cartContent">
                    <div className="imageView">
                      {" "}
                      <img
                        src={`${imageURL + item?.image[0]}`}
                        className="cartProductImage"
                        alt="productImage"
                        crossOrigin="anonymous"
                      />
                    </div>

                    <div className="detailView">
                      {" "}
                      <div>
                        <p className="productName">
                          {item?.title || "Product Name"}
                        </p>
                        <p className="productDescription text-truncate">
                          {item?.description}
                        </p>
                        <div className="d-flex justify-content-between align-items-center mt-1">
                          <div className="row">
                            <p className="col-4 m-0">
                              <span className="productInfo">Quantity: </span>{" "}
                              {allCount[item?._id]?.count ?? item?.quantity}
                            </p>
                            <p className="col-4 m-0">
                              <span className="productInfo">Weight:</span>{" "}
                              {item?.weight || ""}
                            </p>
                            <p className="col-4 m-0">
                              <span className="productInfo">Origins:</span>{" "}
                              {item?.origins || ""}
                            </p>
                            <p className="col-4 m-0">
                              <span className="productInfo">Roast:</span>{" "}
                              {item?.roast || ""}
                            </p>
                            <p className="col-4 m-0">
                              {" "}
                              <span className="productInfo">Bean:</span>{" "}
                              {item?.bean || ""}
                            </p>
                            <p className="col-4 m-0">
                              <span className="productInfo">Price:</span> $
                              {Number(item?.total_price).toFixed(2) || ""}
                            </p>
                            <p className="col-4 m-0">
                              {vendorName ? (
                                <>
                                  <span className="productInfo">
                                    Vendor Name:{" "}
                                  </span>
                                  <span className="vendorName">
                                    {vendorName}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="productInfo">Admin: </span>
                                  Dr Jack's
                                </>
                              )}
                            </p>
                            <p>
                              <span className="productInfo">
                                {" "}
                                Total Price:{" "}
                              </span>
                              $
                              {(
                                allCount[item?._id]?.amount.toFixed(2) ??
                                item?.finalPrice.toFixed(2) ??
                                ""
                              ).toLocaleString("en-IN")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <div className="d-flex justify-content-around align-items-center qtySelection border border-black rounded-pill mt-1">
                          <button
                            className="btn"
                            onClick={() => {
                              decrementCount({
                                id: item?._id ?? "",
                                amount: item?.total_price,
                                orderCount: item?.quantity,
                                bean: item?.bean,
                              });
                            }}
                          >
                            <Minus />
                          </button>
                          <input
                            type="text"
                            value={allCount[item?._id]?.count ?? item?.quantity}
                            className="w-100 text-center fw-bold bg-transparent p-0"
                          />
                          <button
                            className="btn"
                            onClick={() => {
                              incrementCount({
                                id: item?._id,
                                amount: item?.total_price,
                                orderCount: item?.quantity,
                                bean: item?.bean,
                              });
                            }}
                          >
                            <Plus />
                          </button>
                        </div>

                        <div className="border border-black-2 rounded-pill d-flex deleteSection justify-content-center align-items-center dltItem">
                          <button
                            className="btn"
                            onClick={() => deleteItem(item?._id)}
                            title="Delete Cart data"
                            // disabled={getCartListState?.cartItems?.length === 0}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill="none"
                            >
                              <path
                                d="M2.5 6L2.85207 15.1537C2.93467 17.3015 4.69902 19 6.84835 19C8.15157 19 9.65679 19 11.1509 19C13.3002 19 15.0653 17.3015 15.1479 15.1537L15.5 6"
                                stroke="black"
                                strokeWidth="1.4"
                                strokeLinecap="round"
                              />
                              <path
                                d="M5.99997 2.91485L6.99997 2.91485C6.99997 2.91485 7.34514 1.21454 8.99997 1.21454C10.6548 1.21453 11 2.91486 11 2.91486L12 2.91486"
                                stroke="black"
                                strokeWidth="1.4"
                                strokeLinecap="round"
                              />
                              <path
                                d="M11 12V14"
                                stroke="black"
                                strokeWidth="1.4"
                                strokeLinecap="round"
                              />
                              <path
                                d="M7 12V14"
                                stroke="black"
                                strokeWidth="1.4"
                                strokeLinecap="round"
                              />
                              <path
                                d="M17 3.70001L15.2761 3.54917C11.1 3.18375 6.89998 3.18375 2.72387 3.54917L1 3.70001"
                                stroke="black"
                                strokeWidth="1.4"
                                strokeLinecap="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div className="cartScreen mt-3">
          <h3 className="emptyCartText">Your cart is empty.</h3>
          <h6>
            Add items to your cart to proceed or{" "}
            <a href="/"> Continue Shopping </a>
          </h6>
        </div>
      )}
    </>
  );

  return (
    <div className="h-100 py-5" style={{ backgroundColor: "#f4f2f0" }}>
      <div className="container h-100 position-relative z-1">
        {isLoggedIn ? renderCartContent() : renderLoginPrompt()}
      </div>
    </div>
  );
};
