import { apiInstanceWithout } from "../../httpclient";
import * as actions from "./index";

export const GetAllProductAction =
  (search, pageNumber, filters, userId, setLoaderPage) => (dispatch) => {
    new Promise((resolve, reject) => {
      dispatch(actions.getProductRequest());
      apiInstanceWithout
        .get(
          `product/all?pageNumber=${pageNumber}&search=${search}&sort=${filters}&userId=${
            userId ?? ""
          }`
        )
        .then((result) => {
          dispatch(actions.getProductSuccess(result.data.data));
          setLoaderPage(false);
          resolve(result.data);
        })
        .catch((error) => {
          dispatch(actions.getProductError(error?.response?.message));
          reject(error);
        });
    });
  };
