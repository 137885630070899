import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";
import * as actions from "./index";

export const GetCategoryDetailAction =
  (pageNumber, search, filters, missions, origins, roast, setLoaderPage) =>
  (dispatch) => {
    const token = getToken();
    new Promise((resolve, reject) => {
      dispatch(actions.getCategoryDetailRequest());
      apiInstance
        .get(
          `product/all?&origins=${origins}&missions=${missions}&roast=${roast}&search=${search}&sort=${filters}&pageNumber=${pageNumber}`)
        .then((result) => {
          dispatch(actions.getCategoryDetailSuccess(result.data.data));
          setLoaderPage(false);
          resolve(result.data);
        })
        .catch((error) => {
          dispatch(actions.getCategoryDetailError(error?.response?.message));
          reject(error);
        });
    });
  };
