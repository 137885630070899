import React from "react";
import { Link } from "react-router-dom";

const ReturnPolicy = () => {
  return (
    <>
      <section
        className="testimonials-section h-100 p-3"
        style={{ backgroundColor: "#f4f2f0" }}
      >
        <div className="auto-container">
          <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
            <div className="row clearfix">
              <div
                className="title-column col-lg-12 col-md-12 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <h2>Return Privacy Policy</h2>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 wow fadeIn animated">
              <p className="outStoryContent">
                <span className="outStortContentParagraph">Return Policy</span>,
                Coffee Purchases at Dr. Jack's Coffee
              </p>
            </div>
            <p className="outStoryContent">
              At Dr. Jack's Coffee, we take pride in offering the finest coffee
              beans and blends. To uphold the quality of our products, please
              note that all coffee sales are FINAL. Unfortunately, we cannot
              accept returns or exchanges on coffee purchases.
            </p>
            <p className="outStoryContent">
              We appreciate your understanding and support as we strive to
              provide you with the best coffee experience possible. If you have
              any questions or concerns regarding your order, please don't
              hesitate to reach out to our customer service team at
              <Link to={""}> drjacks@obu.edu.</Link>
            </p>
            <p className="outStoryContent">
              Thank you for choosing Dr. Jack's Coffee!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReturnPolicy;
