import React, { useState, useCallback } from "react";
import "./signup.css";
import "../../assets/css/style.css";
import { AddUser } from "../../redux/action/AddUserAction";
import { EMAIL_REGEX } from "../../utils/constant";
import { useNavigate, Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { RotatingLines } from "../../components/CommonLoader";

export const SignUp = () => {
  const navigate = useNavigate();

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    status: "",
    password: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "US",
    pincode: "",
    phoneNumber: "",
  });

  const handelSubmit = async () => {
    if (!user.firstName) {
      setFirstNameError("* First Name is required");
      return;
    }
    if (!user.lastName) {
      setLastNameError("* Last Name is required");
      return;
    }
    if (!user.email) {
      setEmailError("* Email is required");
      return;
    }
    if (!user.password) {
      setPasswordError("* Password is required");
      return;
    }

    if (!user.phoneNumber) {
      setPhoneNumberError("* Phone Number is required");
      return;
    }

    const digitCount = user.phoneNumber.replace(/\D/g, "").length;
    if (digitCount !== 10) {
      setPhoneNumberError("* Phone Number must contain exactly 10 digits");
      return;
    }

    if (!user.password) {
      setPasswordError("* Password is required");
      return;
    }
    if (!user.address) {
      setAddressError("* Address is required");
      return;
    }
    if (!user.city) {
      setCityError("* City Name is required");
      return;
    }
    if (!user.state) {
      setStateError("* State Name is required");
      return;
    }
    if (user.pincode.length !== 5 || !/^\d+$/.test(user.pincode)) {
      setPincodeError("* Zip must contain exactly 5 digits");
      return;
    }

    // Validate email format
    if (!EMAIL_REGEX.test(user?.email)) {
      setEmailError("* Invalid Email");
      return;
    }

    // Validate password length
    if (user.password.length < 8) {
      setPasswordError("* Password must be at least 8 characters long");
      return;
    }

    try {
      const data = {
        first_name: user?.firstName,
        last_name: user?.lastName,
        email: user?.email,
        password: user?.password,
        address: user?.address,
        address2: user?.address2,
        city: user?.city,
        state: user?.state,
        country: "US",
        pincode: user?.pincode,
        phone_number: user?.phoneNumber,
      };

      const res = await AddUser(data, setLoading);

      if (res.status === 200 || res.status === 201) {
        toast.success("User created successfully.");
        navigate("/login", {
          state: {
            url: "/",
          },
        });
      } else {
        toast.error(res?.data?.displayMsg);
      }
    } catch (error) {
      toast.error("An error occurred while processing your request.");
    }
  };

  const handleFormData = useCallback(
    (e) => {
      const { name, value } = e.target;
      setUser({ ...user, [e.target.name]: e.target.value });

      // Clear error when input is provided
      switch (e.target.name) {
        case "firstName":
          setFirstNameError(
            e.target.value.length > 0 ? "" : "* First Name is required"
          );
          break;
        case "lastName":
          setLastNameError(
            e.target.value.length > 0 ? "" : "* Last Name is required"
          );
          break;
        case "email":
          setEmailError(e.target.value.length > 0 ? "" : "* Email is required");
          break;
        case "password":
          const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@*()_+\-=[\]{};':"\\|,.<>/?!#$@&%*]).{8,}$/;
          // var test = passwordRegex.test(e.target.value);
          const isPasswordValid = passwordRegex.test(e.target.value);
          const isNotEmpty = e.target.value.length > 0;
          const isLengthValid = e.target.value.length >= 8; // Minimum length requirement
          setPasswordError(
            !isPasswordValid
              ? "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
              : !isLengthValid && isNotEmpty
              ? "Password must be at least 8 characters long."
              : !isNotEmpty
              ? "Password is required."
              : ""
          );
          // setPasswordError(
          //   e.target.value.length > 0 ? "" : "* Password is required"
          // );
          break;
        case "address":
          setAddressError(
            e.target.value.length > 0 ? "" : "* Address is required"
          );
          break;
        case "city":
          setCityError(
            e.target.value.length > 0 ? "" : "* City Name is required"
          );
          break;
        case "state":
          setStateError(
            e.target.value.length > 0 ? "" : "* State Name is required"
          );
          break;
          case "pincode":
            setPincodeError(
              value.length === 5 && /^\d+$/.test(value)
                ? ""
                : "* Zip must contain exactly 5 digits"
            );
            break;
        case "phoneNumber":
          setPhoneNumberError(
            e.target.value.length > 0 ? "" : "* phoneNumber is required"
          );
          break;
        default:
          break;
      }
    },
    [user]
  );

  const stateOptions = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const handleStateChange = (e) => {
    setUser({ ...user, state: e.target.value });
  };

  return (
    <section className="container position-relative z-1">
      <div className="sec-title style-two" style={{ marginBottom: "30px" }}>
        <div
          className="title-column col-lg-12 col-md-12 col-sm-12"
          style={{ textAlign: "center" }}
        >
          <h2>SignUp</h2>
        </div>
      </div>
      <div className="mb-5 card p-2 justify-content-center border bborder-light-subtle shadow-lg p-3 bg-body-tertiary rounded">
        <div className="mb-2 row d-flex  ">
          <h4 className="mb-4">
            <span className="mb-4 border-bottom">
              <b>Basic Info</b>
            </span>
          </h4>
          <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
            <label htmlFor="fname" className="form-label">
              First Name <span className="red-asterisk">*</span>
            </label>
            <input
              value={user.firstName}
              onChange={handleFormData}
              name="firstName"
              className="form-control"
              id="fname"
              placeholder="First Name"
            />
            <span className="text-danger">{firstNameError}</span>
          </div>
          <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
            <label for="lname" className="form-label">
              Last Name <span className="red-asterisk">*</span>
            </label>
            <input
              value={user.lastName}
              onChange={handleFormData}
              name="lastName"
              className="form-control"
              id="lname"
              placeholder="Last Name"
            />
            <span className="text-danger">{lastNameError}</span>
          </div>
          <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
            <label for="email" className="form-label">
              Email <span className="red-asterisk">*</span>
            </label>
            <input
              value={user.email}
              onChange={handleFormData}
              name="email"
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
            />
            <span className="text-danger">{emailError}</span>
          </div>
          <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
            <label htmlFor="phone-number" className="form-label">
              Phone Number <span className="red-asterisk">*</span>
            </label>
            <InputMask
              mask="(999) 999-9999"
              value={user.phoneNumber}
              onChange={handleFormData}
              name="phoneNumber"
              className="form-control"
              placeholder="Phone Number"
            />
            {phoneNumberError && (
              <span className="text-danger">{phoneNumberError}</span>
            )}
          </div>

          <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
            <label for="password" className="form-label">
              Password <span className="red-asterisk">*</span>
            </label>
            <div className="input-group">
              <input
                value={user.password}
                onChange={handleFormData}
                type={showPassword ? "text" : "password"}
                name="password"
                className="form-control m-0"
                id="password"
                placeholder="Password"
              />
              <span
                className="input-group-text"
                id="basic-addon2"
                onClick={handleClickShowPassword}
              >
                {showPassword ? (
                  <i className="bi bi-eye-slash"></i>
                ) : (
                  <i className="bi bi-eye"></i>
                )}
              </span>
            </div>
            <span className="text-danger mt-2">{passwordError}</span>
          </div>
        </div>
        <div className="border mb-4" />
        <div className=" mb-4  row d-flex justify-content-center">
          <h4 className="mb-4">
            <span className="border-bottom">
              <b>Address</b>
            </span>
          </h4>
          <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
            <label for="addLineOne" className="form-label">
              Address Line 1 <span className="red-asterisk">*</span>
            </label>
            <input
              value={user.address}
              className="form-control"
              onChange={handleFormData}
              name="address"
              id="addLineOne"
              placeholder="Address 1"
            />
            <span className="text-danger">{addressError}</span>
          </div>
          <div className="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
            <label for="addLineTwo" className="form-label">
              Address Line 2
            </label>
            <input
              value={user.address2}
              className="form-control"
              onChange={handleFormData}
              name="address2"
              id="addLineTwo"
              placeholder="Address 2"
            />
          </div>

          <div className="mb-3 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
            <label for="city" className="form-label">
              City <span className="red-asterisk">*</span>
            </label>
            <input
              className="form-control"
              onChange={handleFormData}
              name="city"
              id="city"
              placeholder="City"
            />
            <span className="text-danger">{cityError}</span>
          </div>
          <div className="mb-3 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <label htmlFor="stateSelect" className="form-label">
              State <span className="red-asterisk">*</span>
            </label>
            <select
              className="form-select"
              onChange={handleStateChange}
              name="state"
              id="stateSelect"
              value={user.state}
            >
              <option value="">Select State</option>
              {stateOptions.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {!user.state && <span className="text-danger">{stateError}</span>}
          </div>
          <div className="mb-3 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
            <label for="country" className="form-label">
              Country <span className="red-asterisk">*</span>
            </label>
            <input
              className="form-control"
              // onChange={handleFormData}
              name="country"
              id="country"
              placeholder="Country"
              value={"USA"}
              disabled
            />
          </div>
          <div className="mb-3 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
            <label for="zip" className="form-label">
              ZIP <span className="red-asterisk">*</span>
            </label>
            <input
              value={user.pincode}
              className="form-control"
              onChange={handleFormData}
              name="pincode"
              id="zip"
              placeholder="Zip"
              maxLength={5}

            />
            <span className="text-danger">{pincodeError}</span>
          </div>
        </div>
      </div>

      <div className="mb-2 d-flex align-items-center justify-content-center">
        <div className="d-grid col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-6">
          <Link
            className="btn"
            onClick={handelSubmit}
            style={{ backgroundColor: "#443c02" }}
          >
            {loading ? (
              <RotatingLines />
            ) : (
              <text type="button" className="text-light">
                Create New Account
              </text>
            )}
          </Link>
        </div>
      </div>
      <div className="mb-5 d-flex align-items-center justify-content-center">
        <text className="fs-6 me-2">Already have an account?</text>
        <Link className="fs-6" to="/login">
          <text className="border-bottom">Login</text>
        </Link>
      </div>
    </section>
  );
};
