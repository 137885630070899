import { apiInstance } from "../../httpclient/index";
import { getToken } from "../../utils/Storage";

export const SendMessageAction = async (data) => {
  const token = getToken();
  try {
    const res = await apiInstance.post("feedback/add", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
    return null;
  } catch (err) {
    console.error("Error in SendMessageAction:", err);
    return err.response;
  }
};
