import React from "react";
import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { GetCategoryAction } from "../../redux/action/GetCategoryAction";
// import { imageURL } from "../../utils/constant";
// import { CircularProgress } from "../../components/CommonLoader";

import "../../assets/css/style.css";
import { Missions, Origin, Roast } from "../../assets/images";

export const Category = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const categoryList = [
    {
      name: "origins",
      image: Origin,
    },
    {
      name: "missions",
      image: Missions,
    },
    {
      name: "roast",
      image: Roast,
    },
  ];

  // const GetAllCategory = () => dispatch(GetCategoryAction());

  // const getAllCategoryState = useSelector(
  //   (state) => state?.getAllCategory?.categoryGetAllData
  // );
  // const fetching = useSelector((state) => state.getAllCategory.fetching);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await GetAllCategory();
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);


  return (
    <>
      {/* {fetching ? (
        <CircularProgress />
      ) : ( */}
      <section className="services-section" style={{ paddingBottom: "0px" }}>
        <div className="container">
          <div className="sec-title style-two">
            <div className="row clearfix">
              <div className="title-column col-lg-12 col-md-12 col-sm-12">
                <h2>
                  Our <span>Categories</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row g-5 clearfix">
            {/* {getAllCategoryState === null || getAllCategoryState?.loading ? (
                <CircularProgress />
              ) : getAllCategoryState &&
                getAllCategoryState.result &&
                getAllCategoryState?.result?.length > 0 ? (
                getAllCategoryState?.result?.map((category, index) => {
                  const image_Id = category?.image?.[0];
                  const imageUrl = image_Id
                    ? `${imageURL + image_Id}`
                    : "../../assets/images/banner1-1.jpg";
                  return (
                    <div
                      className="category-service-block col-xl-3 col-lg-4 col-md-6 col-12 wow fadeIn mb-5"
                      key={index}
                    >
                      <div
                        className="inner-box"
                        onClick={() => {
                          navigate(`/categoryDetail/${category.name}`);
                        }}
                      >
                        <div>
                          <div className="image-box">
                            <img
                              src={category.image}
                              alt="img"
                              crossorigin="anonymous"
                              className="border"
                            />
                          </div>
                        </div>
                        <div className="caption-box">
                          <h3 className="categoryName">{category?.name}</h3>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-products-found">
                  <p>No category found</p>
                </div>
              )} */}
            {categoryList?.map((category, index) => {
              return (
                <div
                  className="category-service-block col-xl-3 col-lg-4 col-md-6 col-12 wow fadeIn mb-5"
                  key={index}
                >
                  <div
                    className="inner-box"
                    onClick={() => {
                      navigate(`/categoryDetail/${category?.name}`);
                    }}
                  >
                    <div>
                      <div className="image-box">
                        <img
                          src={category?.image}
                          alt="img"
                          crossorigin="anonymous"
                          className="border"
                        />
                      </div>
                    </div>
                    <div className="caption-box">
                      <h3 className="categoryName">{category?.name}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* )} */}
    </>
  );
};
