import React, { useEffect, useState } from "react";
import "./order.css";
import { GetOrdersAction } from "../../redux/action/GetOrdersAction";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../utils/Storage";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Chip } from "@mui/material";
import moment from "moment";
import { CircularProgress } from "../../components/CommonLoader";
import momenttz from 'moment-timezone';

export const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderPage, setLoaderPage] = useState(false);
  const [page, setPage] = useState(1);

  const getAllOrdersState = useSelector(
    (state) => state?.getOrders?.orderGetAllData
  );

  const checkLoginStatus = () => {
    setIsLoggedIn(!!getToken());
  };

  const GetAllOrder = (page) => dispatch(GetOrdersAction(page, setLoaderPage));

  useEffect(() => {
    checkLoginStatus();
    if (isLoggedIn) {
      GetAllOrder(page); 
    }
  }, [isLoggedIn, page]); 

  const formatDate = (date) => {
    const timezone = momenttz.tz.guess(); 
    const centralDate = moment(date).tz(timezone);
    return centralDate.format("MM/DD/YYYY, h:mm A");
  };

  return (
    <div style={{ backgroundColor: "#f4f2f0" }}>
      <div className="container order-list position-relative z-1">
        {isLoggedIn ? (
          <>
            {loader ? (
              <CircularProgress />
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="yourOrderTitle mt-4">
                    <h2>My Orders</h2>
                  </div>
                </div>
                <div className="divider mb-5" />
                {getAllOrdersState === null ? (
                  <CircularProgress />
                ) : getAllOrdersState?.result &&
                  getAllOrdersState?.result.length > 0 ? (
                  <div className="order-detail-body">
                    <InfiniteScroll
                      className="hide-scroll"
                      dataLength={getAllOrdersState?.result?.length ?? 10}
                      next={() => {
                        if (getAllOrdersState?.meta?.nextPage) {
                          setLoaderPage(true);
                          setPage((prevPage) => prevPage + 1); 
                        }
                      }}
                      loader={loaderPage && <CircularProgress />}
                      hasMore={
                        getAllOrdersState?.meta?.totalPages > page 
                      }
                    >
                      {getAllOrdersState?.result?.map((order) => (
                        <div key={order._id} className="order-box">
                          <div className="d-flex justify-content-between align-align-items-center mb-1">
                            <h5 className="mb-0">Order ID: {order?.orderNumber}</h5>
                          </div>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Bean</th>
                                <th scope="col">Vendor</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Price</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.accounting?.cartAccountingList?.map(
                                (orderItem) => {
                                  const itemTotalPrice =
                                    Number(orderItem?.totalPrice) || 0;

                                  const vendorShippingCosts =
                                    order?.accounting?.vendorShippingCosts;

                                  const firstShippingCost = vendorShippingCosts
                                    ? Number(
                                        Object.values(vendorShippingCosts)[0]
                                      )
                                    : 0;

                                  const finalPrice = (
                                    itemTotalPrice + firstShippingCost
                                  ).toFixed(2);

                                  const vendorName =
                                    orderItem?.vendorDetails?.first_name &&
                                    orderItem?.vendorDetails?.last_name
                                      ? `${orderItem.vendorDetails.first_name} ${orderItem.vendorDetails.last_name}`
                                      : null;

                                  return (
                                    <tr key={orderItem?.productId}>
                                      <td>
                                        <div className="product-info">
                                          <h3>{orderItem?.productName}</h3>
                                        </div>
                                      </td>
                                      <td>{orderItem?.bean}</td>
                                      <td>
                                        {vendorName ? (
                                          <>{vendorName}</>
                                        ) : (
                                          <>Dr Jack's</>
                                        )}
                                      </td>
                                      <td>{orderItem?.quantity}</td>
                                      <td>${finalPrice}</td>
                                      <td>{formatDate(order?.createdAt)}</td>
                                      <td>
                                        {order?.status === "P" && (
                                          <Chip
                                            label="Pending"
                                            size="small"
                                            color="warning"
                                          />
                                        )}
                                        {order?.status === "C" && (
                                          <Chip
                                            label="Cart"
                                            size="small"
                                            color="warning"
                                          />
                                        )}
                                        {order?.status === "CL" && (
                                          <Chip
                                            label="Cancelled"
                                            size="small"
                                            color="error"
                                          />
                                        )}
                                        {order?.status === "PD" && (
                                          <Chip
                                            label="Payment Declined"
                                            size="small"
                                            color="error"
                                          />
                                        )}
                                        {order?.status === "PF" && (
                                          <Chip
                                            label="Payment Failed"
                                            size="small"
                                            color="error"
                                          />
                                        )}
                                        {order?.status === "S" && (
                                          <Chip
                                            label="Shipped"
                                            size="small"
                                            color="secondary"
                                          />
                                        )}
                                        {order?.status === "PC" && (
                                          <Chip
                                            label="Paid"
                                            size="small"
                                            color="success"
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </InfiniteScroll>
                    {getAllOrdersState?.meta?.totalCount ===
                      getAllOrdersState?.result?.length &&
                    getAllOrdersState?.result?.length ? (
                      <p className="mt-4" style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    ) : null}
                  </div>
                ) : (
                  <p className="text-align-center">No orders found.</p>
                )}
              </>
            )}
          </>
        ) : (
          <div className="card loginCard position-relative z-1">
            <div className="loginPrompt">
              <p className="mb-4">Please log in to view your Order History.</p>
              <button
                className="proceed-To-Buy"
                onClick={() => {
                  navigate("/login", {
                    state: {
                      url: "/order",
                    },
                  });
                }}
              >
                Login
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
