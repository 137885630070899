import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";

export const DeleteCartItem = async (productId) => {
  const token = getToken();
  try {
    const res = await apiInstance.post("cart/removeProduct", {productId}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.error("Error deleting cart:", err);
    return null;
  }
};
