import React from "react";
import { Nav, Tab } from "react-bootstrap";

const MissionsCategoryTab = ({ setMissions, setPage }) => {
  return (
    <div>
      <Tab.Container defaultActiveKey={"End Human Trafficking"}>
        <Nav variant="tabs" className="mb-3">
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="End Human Trafficking"
              onClick={() => {
                setMissions("End Human Trafficking");
                setPage(1);
              }}
            >
              End Human Trafficking
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer mx-sm-2">
            <Nav.Link
              eventKey="Latin America Evangelism and Mission"
              onClick={() => {
                setMissions("Latin America Evangelism and Mission");
                setPage(1);
              }}
            >
              Latin America Evangelism and Mission
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="Economic Development & Education"
              onClick={() => {
                setMissions("Economic Development_Education");
                setPage(1);
              }}
            >
              Economic Development & Education
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="Supporting Wounded Veterans"
              onClick={() => {
                setMissions("Supporting Wounded Veterans");
                setPage(1);
              }}
            >
              Supporting Wounded Veterans
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="Funding Good in the World"
              onClick={() => {
                setMissions("Funding Good in the World");
                setPage(1);
              }}
            >
              Funding Good in the World
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="Food Insecurity"
              onClick={() => {
                setMissions("Food Insecurity");
                setPage(1);
              }}
            >
              Food Insecurity
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
    </div>
  );
};

export default MissionsCategoryTab;
