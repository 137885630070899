import { setToken } from "../../httpclient/clientHelper";
import { apiInstance } from "../../httpclient/index";    
import { setUserSessionId, setUserSessionToken } from "../../utils/Storage";

export const LoginAction = async (data, setLoading) => {
  setLoading(true);
  try {
    const res = await apiInstance.post("auth/login", data);
    setLoading(false);
    if (res.status === 200) {
      setToken(res.data.data.token);
      setUserSessionToken(res.data.data.token);
      setUserSessionId(res.data.data.user.id);
      return res.data;
    }
    return null;
  } catch (err) {
    setLoading(false);
    return err.response;
  }
};
