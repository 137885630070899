import cookie from "react-cookies";

export const getToken = () => cookie.load("login-token");

export const getUserId = () => cookie.load("user-id");

export const removeUserSession = () => {
  cookie.remove("login-token", { path: "/" });
};

export const setUserSessionToken = (token) => {
  cookie.save("login-token", token, { path: "/" });
};
export const setUserSessionId = (userId) => {
  cookie.save("user-id", userId, { path: "/" });
};
