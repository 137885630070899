import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { getToken, removeUserSession } from "../../utils/Storage";
import "./nav.css";
import { Divider } from "@mui/material";
import { CartBadge } from "./cartBadge";
import { useDispatch } from "react-redux";
import { resetCart } from "../../redux/action";

export const NavBar = () => {
  const dispatch = useDispatch();
  const isLoggedIn = !!getToken();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const logIn = () => {
    if (!(location.pathname === "/login")) {
      const pathname = location.pathname;
      if (!pathname === "signUp") {
        navigate("/login", {
          state: {
            url: pathname,
          },
        });
      } else {
        navigate("/login", {
          state: {
            url: "/",
          },
        });
      }
    } else {
      navigate("/login");
    }
  };

  const signOut = () => {
    localStorage.clear();
    removeUserSession();
    dispatch(resetCart());
    logIn();
  };

  return (
    <>
      <div>
        <div className="header-top">
          <div className="inner-container clearfix">
            <div className="top-right clearfix">
              <div className="link-box contact-form">
                <div
                  className="form-group col-lg-12"
                  style={{ height: "40px" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <nav className="container navbar navbar-expand-md main-header d-flex justify-content-between">
          <Link to={"/"} className="navbar-brand">
            <div
              className={`logo-open ${isMenuVisible ? "toggleLogo" : "logo"}`}
            >
              <img src={logo} alt="logo" />
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={isMenuVisible ? "true" : "false"}
            aria-label="Toggle navigation"
            style={{ margin: "10px" }}
            onClick={toggleMenu}
          >
            <span
              className="navbar-toggler-icon"
              style={{ height: "20px", width: "20px" }}
            ></span>
          </button>

          <div
            className={`collapse navbar-collapse ${
              isMenuVisible ? "show" : ""
            } navColl`}
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link to={"/"} className="nav-link">
                  Home <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/our-products"} className="nav-link">
                  Our Products
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/order"} className="nav-link">
                  My Orders
                </Link>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Contact
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                  style={{ minWidth: "20vh" }}
                >
                  <Link to={"/connectUs"} className="dropdown-item">
                    Contact
                  </Link>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <Link to={"/about"} className="dropdown-item">
                    About Us
                  </Link>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <Link to={"/faqScreen"} className="dropdown-item">
                    Faq
                  </Link>
                </div>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Account
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                  style={{ minWidth: "20vh" }}
                >
                  {isLoggedIn ? (
                    <>
                      <li>
                        <Link to={"/profile"} className="dropdown-item">
                          Profile
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <p className="dropdown-item mb-0" onClick={signOut}>
                          Sign Out
                        </p>
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        onClick={() => {
                          logIn();
                        }}
                      >
                        <Link to={"/login"} className="dropdown-item">
                          Login
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link to={"/signUp"} className="dropdown-item">
                          Create Account
                        </Link>
                      </li>
                    </>
                  )}
                </div>
              </li>

              <li className="nav-item">
                <div className="cart-btn" style={{ marginLeft: "10px" }}>
                  <Link to={"/cart"}>
                    <span
                      className="flaticon-shopping-cart"
                      style={{ color: "black", fontSize: "25px" }}
                    ></span>
                    <CartBadge />
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <Divider style={{ borderColor: "rgba(0, 0, 0, 1.12)" }} />
      </div>
    </>
  );
};
