import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";
import * as actions from "./index";

export const GetOrdersAction = (pageNumber, setLoaderPage) => (dispatch) => {
  const token = getToken();
  new Promise((resolve, reject) => {
    dispatch(actions.getOrderRequest());
    apiInstance
      .get(`order?pageNumber=${pageNumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        dispatch(actions.getOrderSuccess(result?.data?.data));
        setLoaderPage(false);
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.getOrderError(error?.response?.message));
        reject(error);
      });
  });
};
