import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";
import * as actions from "./index";
import { toast } from "react-toastify";

export const UserDetailsAction = (id, navigate) => (dispatch) => {
  const token = getToken();
  new Promise((resolve, reject) => {
    dispatch(actions.getUserDetailRequest());
    apiInstance
      .get(`user?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        dispatch(actions.getUserDetailSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        toast.error(error.message);
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        } else {
          dispatch(actions.getUserDetailError(error.response.message));
          reject(error);
        }
      });
  });
};
