import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/home";
import { About } from "../pages/about/about";
import { ProductDetail } from "../pages/productDetail/productDetail";
import { Login } from "../pages/login/login";
import { UserProfile } from "../pages/profile/profile";
import { SignUp } from "../pages/sign-Up/signup";
import { Gallery, OurProducts } from "../pages/gallery/gallery";
import { Cart } from "../pages/cart/cart";
import { Payment } from "../pages/payment/payment";
import { Order } from "../pages/order/order";
import { Review } from "../pages/review/review";
import { FaqScreen } from "../pages/faq/faqScreen";
import { ConnectUs } from "../pages/ConnectUs/ConnectUs";
import { Product } from "../pages/product/product";
import { DefaultLayout } from "../components/DefaultLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OrderConfirmation } from "../pages/confirmation/orderConfirmation";
import { CategoryDetails } from "../pages/categoryDetails/categoryDetails";
import { Policy } from "../pages/privacyPolicy/privacyPolicy";
import ReturnPolicy from "../pages/returnPolicy/ReturnPolicy";
import ScrollToTop from "../components/ScrollToTop";
import NewPassword from "../pages/login/NewPassword";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <ToastContainer position="bottom-right" />
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route index exact element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/new-password/:key" element={<NewPassword />} />
          <Route path="/our-products" element={<OurProducts />} />
          <Route path="/about" element={<About />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/product" element={<Product />} />
          <Route path="/productDetail/:productId" element={<ProductDetail />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/order" element={<Order />} />
          <Route path="/checkout" element={<Payment />} />
          <Route path="/faqScreen" element={<FaqScreen />} />
          <Route path="/review/:product_id" element={<Review />} />
          <Route path="/connectUs" element={<ConnectUs />} />
          <Route path="/order-confirmed" element={<OrderConfirmation />} />
          <Route
            path="/categoryDetail/:category"
            element={<CategoryDetails />}
          />
          <Route path="/policy" element={<Policy />} />
          <Route path="/return_policy" element={<ReturnPolicy />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
